.amnesty-web-textblock {
  background-color: @body-bg;
  .textblock {
    h1, h2, h3, h4, h5, p {
      padding-left: @xs-box-left;
      padding-right: @xs-box-right;
      @media (min-width: @screen-sm) {
        padding-left: @sm-box-left;
        padding-right: @sm-box-right;
      }
      @media (min-width: @screen-md) {
        padding-left: @md-box-left;
        padding-right: @md-box-right;
      }
      @media (min-width: @screen-lg) {
        padding-left: @lg-box-left;
        padding-right: @lg-box-right;
      }
      @media (min-width: @screen-xl) {
        padding-left: @xl-box-left;
        padding-right: @xl-box-right;
      }
    }
  }
}

.plone-modal .amnesty-web-textblock {
  background-color: @white;
}
