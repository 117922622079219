.portaltype-amnesty-web-thankspage {

  #thankspage-content {
    .grid-center();
    .grid-border();
  }

  #thankspage-body {
    @media (min-width: @screen-md) {
      width: 100% * ((@column-width + @gutter-width) * 8 - @gutter-width) / @gridsystem-width;
    }
  }

  .portletTeaser .megateaser-link {
    display: table;
    width: 100%;
    padding: @xs-box-top @xs-box-right @xs-box-bottom @xs-box-left;
    @media (min-width: @screen-sm) {
      padding: @sm-box-top @sm-box-right @sm-box-bottom @sm-box-left;
    }
    @media (min-width: @screen-md) {
      padding: @md-box-top @md-box-right @md-box-bottom @md-box-left;
    }
    @media (min-width: @screen-lg) {
      padding: @lg-box-top @lg-box-right @lg-box-bottom @lg-box-left;
    }
    @media (min-width: @screen-xl) {
      padding: @xl-box-top @xl-box-right @xl-box-bottom @xl-box-left;
    }

    &:hover, &:focus {
      img {
        transform: none;
        filter: none;
      }
    }

    > .icon {
      vertical-align: top;
      width: 48px;
      height: 48px;
      display: table-cell;
    }

    > .caption {
      max-width: none;
      display: table-cell;
      padding: 0 0 0 @xs-box-left;
      @media (min-width: @screen-sm) {
        padding: 0 0 0 @sm-box-left;
      }
      @media (min-width: @screen-md) {
        padding: 0 0 0 @md-box-left;
      }
      @media (min-width: @screen-lg) {
        padding: 0 0 0 @lg-box-left;
      }
      @media (min-width: @screen-xl) {
        padding: 0 0 0 @xl-box-left;
      }
    }
  }

  .shariff {
    text-align: center;
    .share_text {
      display: none;
    }
    ul {
      display: inline-block;
      list-style-type: none;
      margin: 0;
      padding: 0;
      > li {
        float: left;
        width: 50%;
        @media (min-width: @screen-xs) {
          width: auto;
        }
        > a {
          margin: 0 auto 30px auto;
          background-color: transparent;
          display: block;
          width: 80px;
          height: 60px;
        }
      }

      .facebook > a {
        .icon_facebook_inv_round(60px);
        &:hover {
          .icon_facebook_soft_round(60px);
        }
      }

      .linkedin > a {
        .icon_linkedin_inv_round(60px);
        &:hover {
          .icon_linkedin_soft_round(60px);
        }
      }

      .twitter > a {
        .icon_twitter_inv_round(60px);
        &:hover {
          .icon_twitter_soft_round(60px);
        }
      }

      .mail > a {
        .icon_mail_inv_round(60px);
        &:hover {
          .icon_mail_soft_round(60px);
        }
      }
    }
  }

}
