#latest_article_collection {
  ul {
    li {
      background-color: @ai-blue-extra-2;

      @media (min-width: @screen-md) {
        &:before {
          background-color: @ai-blue-extra-2;
        }

        &:hover:before {
          background-color: @ai-blue-extra-1;
        }
      }

      > a.list-item {
        &:hover {
          background-color: @ai-blue-extra-1;

          img {
            filter: none;
          }
        }

        h2 {
          .title {
            color: @white;
          }
        }

        time {
          color: @white;
        }
      }
    }
  }
}
