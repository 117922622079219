#content {
  .grid-center;
}

#profile-details {
  .grid-border;

  > div {
    float: left;
    width: 100%;

    @media (min-width: @screen-md) {
      margin-left: 100% * @gutter-width / @gridsystem-width;
    }
  }

  #profile-image {
    margin-left: 0;

    @media (min-width: @screen-md) {
      .columns(4);
    }
  }

  #profile-infos {
    box-sizing: border-box;
    @media (min-width: @screen-md) {
      .columns(8);
      box-sizing: border-box;
    }
  }
}

#profile-image {
  margin-bottom: 2em;

  img {
    border-radius: 100%;
  }
}

#profile-infos {
  ul {
    .list;
    display: table;
    border-collapse: collapse;

    > li {
      border-top: 1px solid @grey-5;
      display: table-row;

      > b {
        width: 50%;

        @media (min-width: @screen-md) {
          width: 40%;
        }
      }

      > b, > span {
        padding: 10px 0 20px;
        display: table-cell;
      }
    }
  }
}
