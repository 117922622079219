.pswp__button {
  background-position: 0 0 !important;
  &:before {
    content: none;
  }
  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
}

.pswp {
  .pswp__button--fs {
    .icon_fullscreen_inv();
    margin-top: 5px;
  }

  .pswp__button--close {
    .icon_close_inv();
    margin-top: 5px;
  }

  .pswp__button--arrow--left:before {
    background-position: 0 0 !important;
    .icon_arrowprev_inv();
  }

  .pswp__button--arrow--right:before {
    background-position: 0 0 !important;
    .icon_arrownext_inv();
  }
}


.pswp__counter {
  .t3();
  padding: 0;
  margin: 0 0 0 10px;
}

.pswp--zoom-allowed .pswp__img {
  cursor: default !important;
}
