html {
	height: 100%;
  // overflow-x: visible;
}
body {
	background-color: @body-bg;
	color: @text-color;
	height: 100%;
	.bt();
  margin: 0;
  padding: 0;
  ::selection {
    background: @text-color;
    color: @body-bg;
  }
  ::-moz-selection {
    background: @text-color;
    color: @body-bg;
  }
  [class^="sl-toolbar-"] > li > a.icon-edit:before {
    content: none;
  }
}

h1 {
  .t1();
	.overline {
		.ol1();
	}
	.title {
		.t1();
    padding-bottom: 0;
	}
}
h2 {
  .t2();
}
h3 {
  .t3();
}
h4 {
  .t4();
}
h5 {
  .t5();
}
h6 {
  .t6();
}

.documentFirstHeading {
  .overline {
    padding-top: 0;
  }
}

a {
  color: @link-color;
  text-decoration: none;

  &:hover,
  &:focus {
  	text-decoration: underline;
  }
}
a.prominentLink {
  .pl1();
  color: @link-color;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
  &:after {
    content: "›";
  }
}

p {
  margin-top: 0;
}

strong {
  .bt_strong();
}

q {
  .bt_quote();
  &:before {
    content: "«";
  }
  &:after {
    content: "»";
  }
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}

.overline {
	display: block;
}
.title {
	display: block;
}


table {
  max-width: 100%;
}
th {
  text-align: left;
}
table {
  width: 100%;
  margin-bottom: 36px;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: 8px;
        line-height: 27px;
        vertical-align: top;
        border: 1px solid @grey-5;
        border-collapse: collapse;
      }
    }
  }
  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid @grey-5;
    .bt_strong();
    padding: 8px;
  }
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only,
.hiddenStructure {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
