.template-homepage_view {
  #content {
    max-width: none;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    #content-core {
      max-width: none;
      margin: 0;
    }
  }
}

#image-carousel {
  picture {
    vertical-align: middle;
    display: none;
    &:first-of-type {
      display: block;
    }
  }
  .slick-initialized {
    touch-action: auto;
    picture {
      display: block;
    }
  }
}

.carousel {
  overflow: hidden;
  .carousel-image {
    max-height: 600px;
    position: relative;
    @media (min-width: @screen-xl) {
      overflow: hidden;
      height: 600px;
    }
    img {
      margin-left: -33.333333333%;
      max-width: 133.333333333%;
      width: 133.333333333%;
      @media (min-width: @screen-xs) {
        margin-left: 0;
        max-width: 100%;
        width: 100%;
      }
      @media (min-width: @screen-xl) {
        width: 100%;
      }
    }
    button {
      display: none;
      position: absolute;
      top: 50%;
      height: 60px;
      width: 60px;
      background: rgba(0, 0, 0, .5);
      border: 0;
      margin-top: -30px;
      &:focus {
        outline: 0;
      }
    }
    &:hover {
      button {
        display: block;
      }
    }
  }
  .carousel-caption {
    position: relative;
    background-color: @body-bg;
    @media (min-width: @screen-md) {
      width: 100%;
      padding-bottom: 0;
      .clearfix();
    }
    .carousel-teaser-outer-wrapper {
      @media (min-width: @screen-xs) {
        position: relative;
        margin-top: -20px;
        z-index: 2;
      }
      @media (min-width: @screen-md) {
        position: relative;
        float: left;
        left: 100%;
        margin-left: -100%;
        width: 50%;
        z-index: 2;
        margin-top: -70px;
      }
      .carousel-teaser-inner-wrapper {
        background-color: @yellow;
        @media (min-width: @screen-xs) {
          background: url("++theme++amnesty/images/logo_carousel.svg") @yellow right 0 bottom 0 no-repeat;
          background-size: 220px;
        }
        @media (min-width: @screen-md) {
          margin-right: @gutter-width / 2; // not relative !!!
          .clearfix();
        }
        .carousel-teaser {
          display: none;
          &:first-of-type {
            display: block;
          }
          > a {
            color: @text-color;
            &:hover {
              text-decoration: none;
            }
          }
          h2 {
            margin-top: 0;
            padding: @xs-box-top @xs-box-right 0 @xs-box-left;
            @media (min-width: @screen-sm) {
              padding: @sm-box-top @sm-box-right 0 @sm-box-left;
            }
            @media (min-width: @screen-md) {
              padding: @md-box-top @md-box-right 0 @md-box-left;
            }
            .overline {
              .ol2();
              padding-top: 0;
            }
            .title {
              .t2();
              padding-top: 0;
            }
          }
          .carousel-teaser-text {
            padding: 0 @xs-box-right @xs-box-bottom @xs-box-left;
            @media (min-width: @screen-sm) {
              padding: 0 @sm-box-right @xs-box-bottom @sm-box-left;
            }
            @media (min-width: @screen-md) {
              min-height: 100px;
              padding: 0 @md-box-right @xs-box-bottom @md-box-left;
            }
          }
          .more-link {
            .pl1();
            margin: 0;
            padding-bottom: 0;
            span {
              display: block;
              color: @text-color;
              background-color: @yellow-dark;
              padding-top: 0.5em;
              padding-bottom: 0.5em;
              padding-left: @xs-box-left;
              @media (min-width: @screen-sm) {
                padding-left: @sm-box-left;
              }
              @media (min-width: @screen-md) {
                padding-left: @md-box-left;
              }
              &:hover,
              &:focus {
                text-decoration: none;
              }
            }
          }
          p {
            margin: 0;
            .bt2();
          }
          @media (min-width: @screen-xs) {
            .carousel-teaser-text,
            .more-link {
              padding-right: 100% * (3 * @column-width + 3 * @gutter-width) / (6 * @column-width + 5 * @gutter-width + @margin-width);
            }
          }
          @media (min-width: @screen-md) {
            float: right;
            max-width: 6 * @column-width + 5 * @gutter-width;
            padding-left: 200% * @margin-width / @gridsystem-width;
            h2,
            .carousel-teaser-text {
              padding-left: 0;
            }
            .more-link {
              margin-left: -2000px;
              span {
                padding: 0.5em 0 0.5em 2000px;
              }
            }
          }
        }
      }
    }
  }
}

#carousel-nav {
  display: block;
  width: 100%;
  float: left;
  left: 100%;
  padding: 0;
  background-color: @grey-5;
  overflow: hidden;
  .slick-initialized {
    li {
      display: block;
    }
  }
  ul {
    list-style: none;
    max-width: 100%;
    padding: 0;
    @media (min-width: @screen-md) {
      max-width: @gridsystem-width / 2;
      padding-left: 50%;
    }
    margin: 0;
    box-sizing: content-box;
    li {
      margin: 10px;
      width: 145px;
      float: left;
      display: none;
      &:nth-child(-n+3) {
        display: block;
      }
      > a {
        color: @text-color;
        display: block;
        padding: 1em .5em;
        &:hover,
        &:focus {
          text-decoration: none;
          background-color: @grey-6;
        }
        > .overline {
          .ol6();
          padding-top: 0;
        }
        > .title {
          .t6();
          padding-top: 0;
        }
      }
    }
  }
  .slick-list {
    display: none;
    @media (min-width: @screen-md) {
      display: block;
    }
  }
  .slick-dots {
    background: @grey-5;
    top: 0;
    left: 0;
    display: table;
    list-style: none;
    margin: 0 auto;
    margin-top: 10px;
    li {
      width: 20px;
      height: 40px;
      margin: 0 10px;
      padding: 0;
      cursor: pointer;
      &.slick-active {
        button {
          background: @black;
        }
      }
      button {
        font-size: 0;
        background: @grey-4;
        border: 0;
        border-radius: 50%;
        height: 9px;
        width: 9px;
        padding: 0;
      }
    }
  }
}

#slick-next {
  right: 0;
  border: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  &:before {
    content: "";
    .icon_arrownext_inv(45px);
    width: 45px;
    height: 45px;
  }
}

#slick-previous {
  left: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  &:before {
    content: "";
    .icon_arrowprev_inv(45px);
    width: 45px;
    height: 45px;
  }
}
