.tabs {
  .list();
  border: 1px solid @grey-5;
  border-bottom: none;
  border-left: none;
  display: block;
  width: 100%;
  .clearfix();
  &.accordion {
    > li {
      width: 100%;
      .tab {
        .t6();
        box-sizing: border-box;
        display: inline-block;
        width: 100%;
        padding: 15px @md-box-right 15px @md-box-left;
        color: @grey-3;
        background-color: @body-bg;
        border-bottom: 1px solid @grey-5;
        text-decoration: none;
        line-height: 30px;
        &:hover {
          color: @black;
          background-color: @grey-5;
          text-decoration: none;
        }
        &:after {
          content: "";
          width: 30px;
          height: 30px;
          .icon_expand_soft(30px);
          float: right;
        }
        &.active {
          background-color: @white;
          border-bottom-width: 0;
          color: @black;
          &:after {
            .icon_collapse_dark(30px);
          }
          + .tab-pane {
            display: block;
          }
        }
        + .tab-pane {
          .bt2();
          padding: @xs-box-top @xs-box-right @xs-box-bottom @xs-box-left;
          > h2 {
            display: none;
          }
          .linkList > li:last-child > a {
            border-bottom: none;
          }
        }
      }
    }
  }

  > li {
    display: block;
    float: left;
    > .tab {
      .t6();
      display: block;
      border-left: 1px solid @grey-5;
      padding: 20px 15px;
      color: @grey-3;
      text-decoration: none;
      &:hover {
        background-color: @grey-5;
        color: @black;
        text-decoration: none;
      }
      &.active {
        color: @black;
        background-color: @white;
        border-bottom-color: @white;
      }
    }
    &:first-child {
      > .active {
        border-left: none;
      }
    }
    &:last-child {
      > .tab {
        border-right: 1px solid @grey-5;
      }
    }
  }
}

.tab-pane {
  margin-top: -1px;
  border-top: 1px solid @grey-5;
  background-color: @white;
  display: none;
  .clearfix();
  padding: @xs-box-top @xs-box-right @xs-box-bottom @xs-box-left;
  @media (min-width: @screen-sm) {
    padding: @sm-box-top @sm-box-right @sm-box-bottom @sm-box-left;
  }
  @media (min-width: @screen-md) {
    padding: @md-box-top @md-box-right @md-box-bottom @md-box-left;
  }
  @media (min-width: @screen-lg) {
    padding: @lg-box-top @lg-box-right @lg-box-bottom @lg-box-left;
  }
  @media (min-width: @screen-xl) {
    padding: @xl-box-top @xl-box-right @xl-box-bottom @xl-box-left;
  }
}
