.portaltype-amnesty-web-portalpage {
  #content {
    .documentDescription {
      .grid-center();
      padding: 0 0 45px 0;
    }
    .documentFirstHeading {
      .grid-center();
    }
    .documentByLine {
      display: block;
      width: 100%;
      box-sizing: border-box;
      padding-left: 0;
      padding-right: 0;
      .grid-center();
      .tags {
        @media (min-width: @screen-md) {
          float: left;
          width: 100%;
          padding-right: 280px;
          box-sizing: border-box;
        }
        > .title {
          .t6();
          .grid-border();
          padding-bottom: 12px;
          color: @black;
        }
        > ul {
          box-sizing: border-box;
          border: 1px solid @grey-5;
          padding: 7px;
        }
      }
      .sharing {
        @media (min-width: @screen-md) {
          float: right;
          width: 250px;
          margin-left: -250px;
        }
        > .title {
          .t6();
          .grid-border();
          padding-bottom: 12px;
          color: @black;
        }
        > .shariff, &.shariff {
          .sharing();
          > ul {
            > li {
              border: solid @grey-5;
              border-width: 1px 0 1px 1px;
              &:last-child {
                border-right-width: 1px;
              }
            }
          }
        }
      }
    }
  }
}
