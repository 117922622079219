.cookiebot-button() {
  width: 100%;
  background-color: @grey-6;
  border-radius: 7px;
  padding: 6px 20px 10px;
  border: 0;
  color: @black;
  font-weight: normal;
  font-family: "TradeGothicW01-BoldCn20_675334";
  letter-spacing: 0.001em;
  line-height: 28px;
  font-size: 22px;

  &:hover {
    opacity: 1;
    background-color: @yellow-dark;
  }
}

// CookieBot overlay
body #CybotCookiebotDialog.CybotEdge {

  @media screen and (min-width: @screen-sm) {
    top: 100%;
    transform: translate(-50%,-100%);
    max-width: 100%;
    width: 100%;
    padding: 1em;
  }

  @media screen and (min-width: 990px) {
    padding: 0;
  }

  .CybotCookiebotDialogContentWrapper {
    align-items: center;

    @media screen and (min-width: 1280px) {
      max-width: 990px;  
    }

    > div {
      max-width: 990px;

      @media screen and (min-width: 1280px) {
        max-width: 100%;
      }
    }
  }

  #CybotCookiebotDialogHeader {
    display: none;
  }

  & * {
    font-family: "Trade Gothic Next W01";

    @media screen and (min-width: @screen-sm) {
      font-size: 16px;
    }
  }
  
  #CybotCookiebotDialogBody {
    margin: 1em;
    margin-bottom: 0;

    @media screen and (min-width: 1280) {
      margin-bottom: 1em;
    }

    @media screen and (min-width: 601px) {
      .CybotCookiebotDialogBodyLevelButtonLabel {
        margin-bottom: 0;
        margin-right: 0;
      }
    }

    .CybotCookiebotDialogBodyBottomWrapper {
      justify-content: flex-start;
      align-items: center;
      width: auto;
      border-top: 1px solid #D6D6D6;

      @media screen and (min-width: 1280px) {
        flex-direction: row-reverse;
        justify-content: space-between;
        padding-left: 0;
        margin-bottom: 1em;
      }

      #CybotCookiebotDialogBodyEdgeMoreDetails {
        margin: 0;
        padding: 1em;
        padding-left: 0;

        @media screen and (min-width: 1280px) {
          height: auto;
        }


        a {
          color: @red;

          &:hover {
            text-decoration: underline;
          }

          font-weight: normal;
          display: inline-block;
          &:after {
            content: "›";
            transform: rotate(0deg);
            border: 0;
            display: inline-block;
            height:auto;
            color: @red;
            margin-left: .1em;
          }
        }
      }
    }

    #CybotCookiebotDialogBodyContentTitle {
      .t3();
      padding-top: 0;
    }
    .CybotCookiebotScrollContainer {
      padding: 0;
      border-bottom: none;

      @media screen and (min-width: 1280px) {
          width: 710px;
          max-height: 16.5em;
      }
    }
  }

  #CybotCookiebotDialogFooter {

    @media screen and (min-width: 1280px) {
        width: 225px;
        padding-top: 1em;
        margin-right: 1em;
    }

    @media screen and (min-width: 1510px) {
      position: absolute !important;
      padding-top: 60px;
    }

    #CybotCookiebotDialogBodyButtonsWrapper {
      @media screen and (min-width: 601px) {
          flex-direction: column-reverse;
      }
      @media (min-width: @screen-sm) {
        flex-direction: row;
      }
      @media screen and (min-width: 1280px) {
          flex-direction: column-reverse;
      }
    }

    #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyButton {
      .cookiebot-button();

      @media screen and (min-width: 601px) {
          width: 100%;
          margin-bottom: 0.5em;
      }
      @media (min-width: @screen-sm) {
        width: 33%;
        font-size: 19px;
      }
      @media (min-width: 1280px) {
        width: 100%;
      }

    }
  }

  #CybotCookiebotDialogBodyContent {
    line-height: 20px;
    width: auto;
    padding: 0;

    @media screen and (min-width: 1510px) {
        padding-top: 60px;
    }
  }
}

// All dialogs
#CybotCookiebotDialogFooter #CybotCookiebotDialogPoweredByText,
#CybotCookiebotDialog #CybotCookiebotDialogPoweredbyLink,
body #CookiebotWidget,
body #CybotCookiebotDialogPoweredbyCybot {
  display: none;
}

// All dialogs
body #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogTabContent,
body #CybotCookiebotDialog #CybotCookiebotDialogTabContent {

  @media screen and (min-width: 1280px) {
    margin-left: 0;
  }

  @media screen and (min-width: 1510px) {
    margin: 0 !important;
  }

  .CybotCookiebotDialogTabPanel {
    width: auto;
    height: auto;
    #CybotCookiebotDialogBodyContentText {
      margin-bottom: 1em;
    }
  }

  .CybotCookiebotDialogBodyLevelButtonDescription {
    font-weight: normal;
    color: @grey-2;
  }
  [for="CybotCookiebotDialogBodyLevelButtonNecessary"] .CybotCookiebotDialogBodyLevelButtonDescription {
    color: @grey-4;
  }

  .CybotCookiebotDialogBodyLevelButtonSlider {
    display: none !important;
  }

  .CybotCookiebotDialogBodyLevelButtonWrapper {
    width: 50%;
    flex-direction: row-reverse;
    justify-content: flex-end;

    @media (min-width: 680px) {
      width: 25%;
    }
    @media (min-width: 601px) {
      padding: 1em 0;

      &:not(:last-of-type) {
        border-right: 0;
      }
    }

    @media screen and (min-width: 1280px) {
      width: auto;
      &:last-of-type {
        margin-right: 0;
        padding-right: 0;
      }

    }

  }

  .CybotCookiebotDialogBodyLevelButtonWrapper {
    &:hover {
      .CybotCookiebotDialogBodyLevelButtonDescription {
        color: @black;
      }
      [for="CybotCookiebotDialogBodyLevelButtonNecessary"] .CybotCookiebotDialogBodyLevelButtonDescription {
        color: @grey-4;
      }
    }
  }

  .CybotCookiebotDialogBodyLevelButtonSliderWrapper {
    input[type=checkbox].CybotCookiebotDialogBodyLevelButton {
    opacity: 1;
    filter: grayscale(100%);
    }
  } 
}


body #CybotCookiebotDialog {
  .CybotCookiebotDialogDetailBodyContentCookieLink,
  #CybotCookiebotDialogDetailFooter a {
    color: @red;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      content: "›";
      transform: rotate(0deg);
      border: 0;
      display: inline-block;
      height:auto;
      color: @red;
      margin-left: .1em;
    }

    .CybotExternalLinkArrow {
      display: none;
    }
  }

  .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton,
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount) {
    &:hover {
      color: @black;
    }
  }

  #CybotCookiebotDialogNavList {
    margin-top: 1em;
    .CybotCookiebotDialogNavItem {
      .CybotCookiebotDialogNavItemLink {
        color: @black;
        border-bottom: 0;
        font-family: "TradeGothicW01-BoldCn20_675334";
        font-weight: bold;
        &:hover {
          background-color: @yellow-dark;
        }

        &.CybotCookiebotDialogActive {
          background-color: @yellow;
          &:hover {
            background-color: @yellow-dark;
          }
        }
      }
    }
  }
}

// Detail dialog
body #CybotCookiebotDialog:not(.CybotEdge) {
  #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyButton {
    .cookiebot-button();
  }  
}
