.portaltype-formfolder #content {
  .grid-center();
}

.template-form_view {
  .sl-col-2 {

    &:nth-child(2) {
      #pfg-fieldwrapper {
        .field {
          @media (min-width: @screen-xs) {
            padding-right: 0px;
          }
        }
      }
      .formControls {
        margin: 40px 0;
        input.context {
          width: 100%;
        }
      }
    }

    @media (min-width: @screen-xs) {
      width: 100% * (4 * @column-width + 3 * @gutter-width) / @gridsystem-width;
      &:before {
        width: 100% * (4 * @column-width + 3 * @gutter-width) / @gridsystem-width;
      }
      &:first-child {
        width: 100% * (8 * @column-width + 7 * @gutter-width) / @gridsystem-width;
      }
    }
  }

  // Remove equal height hack
  .sl-column {
    &:before {
      content: none;
    }
  }

  .sl-simplelayout {
    .grid-border();
  }

}
