.portletWrapper {
  float: left;
  width: 100%;
}

.portletTeaser {
  margin-top: 2em;
  background-color: @white;
  padding: 1em 100% * @margin-width / @gridsystem-width;
  float: left;
  width: 100%;
  box-sizing: border-box;
  .clearfix();
  header {
    margin-bottom: 1em;
    h2 {
      display: inline;
      padding-right: 1em;
      .t6;
    }
    a {
      .st;
    }
  }
  a {
    display: inline-block;
    background-color: @yellow;
  }
  .megateaser-link {
    box-sizing: border-box;
    color: @text-color;
    margin: 0 auto 1em auto;
    img {
      transition: transform .1s ease-in;
    }
    &:hover,
    &:focus {
      text-decoration: none;
      overflow: hidden;
      img {
        transform: scale(1.1);
        filter: sepia(100%) saturate(400%);
        filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' ><filter id='saturate150'><feColorMatrix in='SourceGraphic' type='matrix' values='1.5 -.25 -.25 0 0    -.25 1.5 -.25 0 0    -.25 -.25 1.5 0 0    0 0 0 1 0' /></filter></svg>#saturate150") url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' ><filter id='sepia'><feColorMatrix in='SourceGraphic' type='matrix' values='.393 .769 .189 0 0    .349 .686 .168 0 0    .272 .534 .181 0 0    0 0 0 1 0' /></filter></svg>#sepia");;
        -webkit-filter: sepia(100%) saturate(400%);
      }
    }
    .img-wrapper {
      display: inline-block;
      width: 100%;
      float: left;
      overflow: hidden;
      @media (min-width: @screen-sm) {
        max-width: 100% * (4 * @column-width + 3 * @gutter-width) / @gridsystem-width;
      }
    }
  }
  .caption {
    background-color: @yellow;
    padding: @xs-box-top @xs-box-right @xs-box-bottom @xs-box-left;
    @media (min-width: @screen-sm) {
      padding: @sm-box-top @sm-box-right @sm-box-bottom @sm-box-left;
    }
    @media (min-width: @screen-md) {
      padding: @md-box-top @md-box-right @md-box-bottom @md-box-left;
    }
    @media (min-width: @screen-lg) {
      padding: @lg-box-top @lg-box-right @lg-box-bottom @lg-box-left;
    }
    @media (min-width: @screen-xl) {
      padding: @xl-box-top @xl-box-right @xl-box-bottom @xl-box-left;
    }
    float: left;
    box-sizing: border-box;
    @media (min-width: @screen-sm) {
      max-width: 100% * (8 * @column-width + 8 * @gutter-width) / @gridsystem-width;
    }
    h2 {
      margin: 0;
      padding: 0;
    }
    .overline {
      .ol1;
      padding: 0;
    }
    .title {
      .t1;
      padding: 0;
    }
  }
}

@media (min-width: @screen-xs) {
  .portletTeaser {
    header,
    .megateaser-link {
      max-width: @gridsystem-width;
    }
    .megateaser-link {
      display: block;
      color: @text-color;
      background-color: @yellow;
      .clearfix;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
  .megateaser-wrapper {}
}
