.plone-breadcrumb {
  @media (min-width: @screen-xs) {
    .grid-border();
  }
  margin-bottom: 2em;
  max-width: @gridsystem-width;
  margin: 0 auto;
  ol {
    @media (min-width: @screen-xs) {
      white-space: normal;
      overflow-x: visible;
    }
    overflow-x: scroll;
    width: 100%;
    padding: 10px 10px 0 10px;
    white-space: nowrap;
    display: table-cell;
    vertical-align: middle;
  	list-style: none;
  	margin: 0;
  	> li {
  		display: inline-block;
      @media (min-width: @screen-xs) {
        display: block;
        float: left;
      }
      > a, > span {
        .st();
        color: @grey-3;
        padding: 0 5px 10px 5px;
        display: block;
        line-height: normal;
      }
      > a {
  	    &:after {
  	    	content: "›";
          margin-left: 2px;
  	    }
        &:hover {
          text-decoration: none;
          color: @grey-1;
        }
      }
  	}
  }

  .container {
    display: table;
    table-layout: fixed;
    width: 100%;
    border: 1px solid @grey-5;
  }
}

#breadcrumbs-home {
  padding: 10px 5px;
  .icon_home_soft();
  display: table-cell;
  background-position: center center;
  border-right: 1px solid @grey-5;
  vertical-align: middle;
  width: 32px;
  height: 18px;
  font-size: 0;
  text-align: right;

  &:hover {
    .icon_home_dark();
    display: table-cell;
    text-decoration: none;
  }
  &:after {
    .st();
    padding: 0;
    color: @grey-3;
    content: "›";
    margin: 0 2px;
    display: inline-block;
    vertical-align: middle;
  }
}
