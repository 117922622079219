.light {

  .slick-track {
    position: relative;
    > li {
      &:before {
        content: "";
        position: absolute;
        top: 1em;
        bottom: 1em;
        width: inherit;
        border-left: 1px solid @grey-4;
        z-index: -1;
      }
    }
  }

  #carousel-nav ul li {
    margin: 0;
    > a {
      color: @grey-3;
      padding: 25px;

      &:hover {
        color: @black;
        background-color: transparent;
      }

      .overline {
        .t6();
        padding: 0;
      }
      .title {
        .st();
        padding: 0;
      }
    }
  }

  .carousel-caption .carousel-teaser-outer-wrapper {
    margin-top: 0;

    .carousel-teaser-inner-wrapper {
      background: none;

      .carousel-teaser {
        background-color: @grey-5;
        @media (min-width: @screen-lg) {
          width: @gridsystem-width / 2;
        }

        h2 {
          .overline {
            .ol6();
          }
          .title {
            .t5();
            padding: 0;
          }
        }

        .more-link {
          > span {
            .pl1();
            background-color: transparent;
            color: @link-color;
            padding: 0.5em 21px 0.5em 14px;
            @media (min-width: @screen-lg) {
              padding: 0.5em 0 0.5em 2000px;
            }
          }
        }
      }
    }


    .carousel-teaser-text {
      display: none;
    }
  }
}
