.collection {
  ul {
    li {
      .extranet-teaser();

      &:hover,
      &:focus {
        &:before {
          background-color: @ai-blue-extra-1;
        }
      }
    }
  }
}
