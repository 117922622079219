.overline-tag {
  background-color: @purple;
  color: @white;
  padding: 2px 8px;
  margin-right: 5px;
  font-family: "TradeGothicW01-BoldCn20_675334";
  border-radius: 2px;

  &.magazine {
    background-color: @teal;
  }
  
  &.ua {
    background-color: #74b9aa;
  }
  // Add more classes as needed. See the vocabulary in "amnesty.web".
  // Example:
  //&.sun {
  //  background-color: @yellow-dark;
  //}
}
