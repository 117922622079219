.list() {
  display: inline-block;
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.horizontalList() {
  > li {
    display: inline-block;
    float: left;
  }
}

.listTable() {
  display: table;
  width: 100%;
  > li {
    display: table-cell;
  }
}

.grid-center() {
  @media (min-width: @screen-md) {
    max-width: @gridsystem-width;
    margin-left: auto;
    margin-right: auto;
  }
}

.listByline() {
  .list();
  > li > a {
    .st();
    display: table;
    padding: 5px 0;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: @black;
    }
    &:before {
      padding-right: 4px;
      content: "";
      display: table-cell;
      width: 10px;
      height: 10px;
    }
    > span {
      display: table-cell;
    }
  }
}

.downloadsList() {
  header {
    padding: 0;
    > h2 {
      .t6();
    }
  }
  ul {
    .listByline();
    > li > a {
      padding: 5px;
      color: @grey-3;
      &:hover {
        color: @black;
        &:before {
          .icon_download_dark(10px);
        }
      }
      &:before {
        .icon_download_soft(10px);
      }
    }
  }
}

.teaser() {
  background-color: @white;
  .list-item {
    color: @text-color;
    text-decoration: none;
    margin: 0 auto;
    display: inline-block;
    width: 100%;
    transition: background-color .1s ease-out;
    img {
      width: 100%;
      transition: transform .1s ease-in;
    }
    &[href] {
      &:hover,
      &:focus {
        background-color: @grey-5;

        img {
          filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
          /* Firefox 3.5+ */
          filter: gray;
          /* IE6-9 */
          -webkit-filter: grayscale(1);
          /* Google Chrome & Safari 6+ */
          transform: scale(1.1);
        }
      }
    }
    > .image-wrapper {
      padding: 0;
      overflow: hidden;
    }
    > div {
      padding: @xs-box-top @xs-box-right @xs-box-bottom @xs-box-left;
      @media (min-width: @screen-sm) {
        padding: @sm-box-top @sm-box-right @sm-box-bottom @sm-box-left;
      }
      @media (min-width: @screen-md) {
        padding: @md-box-top @md-box-right @md-box-bottom @md-box-left;
      }
      @media (min-width: @screen-lg) {
        padding: @lg-box-top @lg-box-right @lg-box-bottom @lg-box-left;
      }
      @media (min-width: @screen-xl) {
        padding: @xl-box-top @xl-box-right @xl-box-bottom @xl-box-left;
      }
    }
  }
  h2 {
    padding: 0;
  }
  span, div {
    .bt2();
    .highlightedSearchTerm {
      font: inherit;
      letter-spacing: inherit;
      line-height: inherit;
      text-transform: inherit;
      padding: 2px;
      background-color: @yellow;
    }
    .goto-content-link {
      .bt_strong();
      color: @link-color;
      text-decoration: underline;
    }
  }

  .overline-tag {
    .overline-tag;
  }

  .overline {
    .ol3();
    padding-top: 0;
  }
  .title {
    .t3();
    padding-top: 0;
  }
}

.clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.separatorLine() {
  &:before {
    content: "";
    .icon_long_stoke(12px);
    display: inline-block;
    width: 14px;
    height: 6px;
    margin: 0 5px;
  }
}

.equalheight() {
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    left: 0;
  }
}

.grid-border() {
  padding-left: 100% * @gutter-width / @gridsystem-width;
  padding-right: 100% * @gutter-width / @gridsystem-width;
  box-sizing: border-box;
  @media (min-width: @screen-lg) {
    padding-left: 0;
    padding-right: 0;
  }
}

.video-marker() {
  &:after {
    content: "";
    .icon_play_inv();
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background-size: 20px;
    background-position: center;
    z-index: 1;
  }

  &:before {
    content: "";
    background-color: #000;
    border-top-left-radius: 5px;
    opacity: .6;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 40px;
    z-index: 1;
  }
}

.play-button() {
  &:after {
    content: "";
    .icon_play_inv();
    background-size: 40px;
    width: 100px;
    height: 60px;
    margin-left: -50px;
    margin-top: -30px;
    position: absolute;
    left: 50%;
    top: 30%;
    background-position: center;
    border: 1px solid transparent;
    @media (min-width: @screen-lg) {
      background-size: 60px;
      width: 130px;
      height: 80px;
      margin-left: -65px;
      margin-top: -40px;
      top: 50%;
    }
  }

  &:before {
    content: "";
    width: 100px;
    height: 60px;
    margin-left: -50px;
    margin-top: -30px;
    display: block;
    position: absolute;
    left: 50%;
    top: 30%;
    background-color: #000;
    border: 1px solid @white;
    border-radius: 10px;
    opacity: .6;
    @media (min-width: @screen-lg) {
      width: 130px;
      height: 80px;
      margin-left: -65px;
      margin-top: -40px;
      top: 50%;
    }
  }
}


.noselect() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.transform(...) {
  -webkit-transform: @arguments;
  -moz-transform: @arguments;
  -o-transform: @arguments;
  -ms-transform: @arguments;
  transform: @arguments;
}

.translate3d(@x: 0, @y: 0, @z: 0) {
  transform: translate(500px, 0);
  -o-transform: translate(@x, @y);         /* Opera */
  -ms-transform: translate(@x, @y);        /* IE 9 */
  -moz-transform: translate(@x, @y);       /* Firefox */
  -webkit-transform: translate(@x, @y);
  .transform(translate3d(@x, @y, @z));
}

.transition(@property: all,
@duration: 0.2s,
@ease: ease) {
  -webkit-transition: @property @duration @ease;
  -moz-transition: @property @duration @ease;
  -o-transition: @property @duration @ease;
  transition: @property @duration @ease;
}

.hideBackface() {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.background-clip(@argument: padding-box) {
  -moz-background-clip: @argument;
  -webkit-background-clip: @argument;
  background-clip: @argument;
}

.border-radius(@topright: 0,
@bottomright: 0,
@bottomleft: 0,
@topleft: 0) {
  -webkit-border-top-right-radius: @topright;
  -webkit-border-bottom-right-radius: @bottomright;
  -webkit-border-bottom-left-radius: @bottomleft;
  -webkit-border-top-left-radius: @topleft;
  -moz-border-radius-topright: @topright;
  -moz-border-radius-bottomright: @bottomright;
  -moz-border-radius-bottomleft: @bottomleft;
  -moz-border-radius-topleft: @topleft;
  border-top-right-radius: @topright;
  border-bottom-right-radius: @bottomright;
  border-bottom-left-radius: @bottomleft;
  border-top-left-radius: @topleft;
  .background-clip(padding-box);
}

.opacity(@opacity: 0.5) {
  -moz-opacity: @opacity;
  -khtml-opacity: @opacity;
  -webkit-opacity: @opacity;
  opacity: @opacity;
  @opperc: @opacity * 100;
  -ms-filter: ~"progid:DXImageTransform.Microsoft.Alpha(opacity=@{opperc})";
  filter: ~"alpha(opacity=@{opperc})";
}

.box-sizing(@sizing: border-box) {
  -ms-box-sizing: @sizing;
  -moz-box-sizing: @sizing;
  -webkit-box-sizing: @sizing;
  box-sizing: @sizing;
}

.hyphens(@hyphens: auto) {
  hyphens: @hyphens;
  -ms-hyphens: @hyphens;
  -moz-hyphens: @hyphens;
  -webkit-hyphens: @hyphens;
}
