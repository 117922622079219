#footer {
  background-color: @ai-blue-extra-dark;
  color: @white;

  section {
    border-top-color: @ai-blue-extra-1;

    #footer-location {
      &:before {
        .icon_extra_maps_soft();
      }

      &:hover:before {
        .icon_extra_maps_inv();
      }
    }

    #footer-facebook {
      &:before {
        .icon_extra_facebook_soft();
      }

      &:hover:before {
        .icon_extra_facebook_inv();
      }
    }

    #footer-twitter {
      &:before {
        .icon_extra_twitter_soft();
      }

      &:hover:before {
        .icon_extra_twitter_inv();
      }
    }

    #footer-youtube {
      &:before {
        .icon_extra_youtube_soft();
      }

      &:hover:before {
        .icon_extra_youtube_inv();
      }
    }

    #footer-instagram {
      &:before {
        .icon_extra_instagram_soft();
      }

      &:hover:before {
        .icon_extra_instagram_inv();
      }
    }
  }

  .title {
    color: @ai-blue-extra-3;
  }

  a, span, p {
    color: @ai-blue-extra-2;
  }

  a:hover {
    color: @white;
  }
}
