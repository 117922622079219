.dropdown-menu-open {
  #masthead {
    position: relative;
    z-index: 4;
  }
  #offcanvas-overlay {
    visibility: visible;
    opacity: 0.9;
  }
}

.dropdown-menu {
  visibility: hidden;
  position: absolute;
  z-index: 2;
  background-color: @grey-6;
  width: 100%;
  left: 0;
  padding: 3em 0 1em 0;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
}

.dropdown-toggle {
  .mt();
  color: @white;
  padding: 13px 1em;
  display: block;
  text-transform: uppercase;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  &:hover {
    background-color: lighten(@color-masthead, 5%);
  }
  &.open {
    background-color: @grey-6;
    border-bottom: 1px solid @grey-5;
    color: @red;
    + .dropdown-menu {
      visibility: visible;
    }
  }
}

.dropdown-menu-content {
  .clearfix();
  max-width: @gridsystem-width;
  margin: 0 auto;
  .linkListWrapper {
    float: left;
    width: 100% * (8 * @column-width + (8 - 1) * @gutter-width) / @gridsystem-width;
    .linkList {
      float: left;
      &.col-2 {
        width: 100% * (6 * @column-width + (6 - 1) * @gutter-width) / @gridsystem-width;
        margin-left: 100% * @gutter-width / @gridsystem-width;
        &:first-child {
          margin-left: 0;
        }
      }
      &.col-1 {
        width: 100%;
        margin-top: -1px;
      }
      &:last-child {
        li > a {
          border-bottom: none;
        }
      }
    }
  }
  .teaser-col {
    float: left;
    width: 100% * (4 * @column-width + (4 - 1) * @gutter-width) / @gridsystem-width;
    margin-left: 100% * @gutter-width / @gridsystem-width;
  }
}
