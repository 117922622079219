p.callout {
  .t2();
  background: transparent;
  width: 100%;
  float: left;
  padding: 0 0 30px 25px;
  border-left: 6px solid @black;
  box-sizing: border-box;
  .hyphens();
  @media (min-width: @screen-md) {
    float: right;
    width: 48%;
    margin: 0 0 30px 25px;
  }
  .calloutSource {
    .st();
    display: block;
    padding: 20px 0 0 0;
  }
}

.linkList {
  .list();
  background: @body-bg;
  > li {
    &:last-child > a {
      border-bottom: 1px solid @grey-5;
    }
    > a {
      .pl1();
      color: @black;
      display: block;
      padding: 15px 0 30px 15px;
      border-top: 1px solid @grey-5;
      &:after {
        content: "›";
        margin-left: 3px;
      }
      &:hover {
        text-decoration: none;
        background: @grey-5;
      }
      &.prominentLink {
        color: @red;
      }
    }
  }
}

.phoneOverline {
  .ol1();
  padding-top: 0;
  display: block;
}

.inline-quote {
  font-family: "TradeGothicNextW01-Bold_693232";
  letter-spacing: 0.2px;
  font-size: 18px;
  line-height: 27px;
}

.zewo {
  .zewo_logo();
  background-position: right;
  height: 120px;
  background-size: contain;
}

.donationPhone {
  .t1();
  color: @black;
  display: block;
  padding-top: 0;
  &:hover {
    text-decoration: none;
    color: @black;
  }
}

a.imageCaption {

  h2 {
    margin: 0;
    padding: 20px 20px 40px 20px;
    background: @yellow;
    .overline {
      .ol3();
      padding-top: 0;
      color: @black;
    }
    .title {
      .t3();
      padding-top: 0;
      color: @black;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

