
.enable-gallery {
  max-width: 100%;
  position: relative;
  .image-wrapper {
    display: inline-block;
    position: relative;
  }
  &:before, .image-wrapper:before {
    content: "";
    .icon_gallery_inv();
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    right: 0px;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    background-position: 10px;
    border-top-left-radius: 5px;
  }
}

.enable-video {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: .4;
  }

  .video-title {
    .t3();
    margin-top: 30px;
    @media (min-width: @screen-lg) {
      .t1();
      margin-top: 20px;
      top: 50%;
    }
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    text-align: center;
    color: @white;
  }

  .play-button {
    .play-button();
  }
}

p > .enable-gallery:before {
  content: none;
}

figure .enable-gallery {
  display: inline-block;
}

.pswp__button {
  background: none;
}

.pswp__item iframe {
  width: 100%;
  height: 100%;
}

.pswp__ui--fit .pswp__caption, .pswp__caption {
  background-color: transparent !important;
  > .pswp__caption__center {
    max-width: @gridsystem-width;
    padding: @lg-box-top @lg-box-right @lg-box-bottom @lg-box-left;
    > .title {
      .t5();
    }
  }
}
