.document-author {
  display: flex;
  margin-bottom: 9px;

  .author-image {
    border-radius: 100%;
    overflow: hidden;
    max-width: 65px;
    display: inline-block;
  }

  .author-info {
    display: inline-block;
    margin-left: 18px;

    h5 {
      .t6();
      display: block;
      padding: 0;

      small {
        .st();
        color: @text-color-soft;
        padding: 0;
        display: block;
      }
    }

    h6 {
      .st();
      padding: 0;
    }
  }
}
