.template-search,
.template-list_view,
.template-events_listing,
.template-file_listing,
.template-reminders,
.template-magazine_listing,
.template-view.portaltype-amnesty-web-magazine {
  #content-core {
    ul {
      > li {
        .extranet-teaser();
      }
    }
  }
}

&.template-list_view.portaltype-amnesty-extranet-blog #content-core .list-item .byline {
  font-family: "Trade Gothic Next W01";
  font-size: 13px / 100 * @mobile-font-size-factor;
  line-height: 21px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: normal;
  padding: 0 0 18px 0;
  strong, time {
    font-size: 13px / 100 * @mobile-font-size-factor;
  }
  @media (min-width: @screen-xs) {
    font-size: 13px;
    strong, time {
      font-size: 13px;
    }
  }
}
