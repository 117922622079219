.downloads {
  .grid-border();
  .downloadsList();
  @media (min-width: @screen-md) {
    > ul >  {
      border: 1px solid @grey-5;
      padding: 10px;
      margin-left: -5px;
      margin-top: 20px;
      box-sizing: border-box;
      li {
        float: left;
      }
    }
  }
}