// Override the position style of built in tinyMCE menu because we display the menu in an overlay
.overlay-open .mce-menu {
  position: fixed;
}

.overlay {

  &.overlay-add, &.overlay-delete, &.overlay-edit, &.overlay-upload {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    height: auto !important;
    width: auto !important;
    position: fixed !important;
    margin: 0 !important;
    overflow: auto;
  }

  > .pb-ajax {
    padding: 1em;
    height: 100%;
    box-sizing: border-box;

    > div {
      padding: 0;
      width: 100%;
    }
  }
}

