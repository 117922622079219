.overline-tag {
  background-color: @purple;
  color: @white;
  padding: 2px 8px;
  margin-right: 5px;
  font-family: "TradeGothicW01-BoldCn20_675334";
  border-radius: 2px;

  // Add more classes as needed. See the vocabulary in "amnesty.web".
  &.action_blog {
    background-color: @ai-blue-extra-2 !important;
  }
}
