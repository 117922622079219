#login-content-row {
  > div {
    float: left;
    width: 100%;

    @media (min-width: @screen-sm) {
      margin-left: 100% * @gutter-width / @gridsystem-width;
    }
  }

  #login-left-side {
    margin-left: 0;
    @media (min-width: @screen-sm) {
      .columns(8);
    }
  }

  #login-right-side {
    @media (min-width: @screen-sm) {
      .columns(4);
      padding-top: 33px; // The same padding in top as the title on the other side has
      box-sizing: border-box;
    }

    #info-box {
      background-color: white;

      // paddings and title / overline taken from the .teaser mixin
      padding: @xs-box-top @xs-box-right @xs-box-bottom @xs-box-left;
      @media (min-width: @screen-sm) {
        padding: @sm-box-top @sm-box-right @sm-box-bottom @sm-box-left;
      }
      @media (min-width: @screen-md) {
        padding: @md-box-top @md-box-right @md-box-bottom @md-box-left;
      }
      @media (min-width: @screen-lg) {
        padding: @lg-box-top @lg-box-right @lg-box-bottom @lg-box-left;
      }
      @media (min-width: @screen-xl) {
        padding: @xl-box-top @xl-box-right @xl-box-bottom @xl-box-left;
      }

      h2 {
        padding: 0;

        .overline {
          .ol3();
          padding-top: 0;
        }

        .title {
          .t3();
          padding-top: 0;
        }
      }
      p {
        a {
          .t6();
        }
      }
    }
  }
}

