@button-padding-top: 4px;
@button-padding-bottom: 4px;


#masthead {
  background-color: @color-masthead;
  .clearfix;
  position: relative;
  z-index: 1;

  @media (min-width: @screen-xs) {
    padding: 4px 0;
  }
}
.site-search {
  .st();
  line-height: 27px;
  float: left;
  display: block;
  padding: @button-padding-top 10px @button-padding-top 10px;
  border: 1px solid lighten(@color-masthead, 10%);
  border-left: none;
  .border-radius(7px, 7px, 0, 0);
  color: @text-color-inverse;
  margin: 5px 0;
  width: auto;
  height: auto;
  background-size: 32px;
  background-position: left;

  &:before {
    content: "";
    .icon_serach_inv();
    display: inline-block;
    width: 26px;
    height: 25px;
    float: left;
    margin-right: 5px;
  }

  &:hover {
    text-decoration: none;
    background-color: lighten(@color-masthead, 5%);
  }

  @media (min-width: @screen-xs) {
    margin: 3px 0;
  }
}

#logo {
  float: left;
  padding: 2px 0 4px 1em;
  padding-left: 1em;
}

#navbar {
  display: none;
  padding: 0;
  @media (min-width: @screen-md) {
    .mt();
    padding: 0;
    display: block;
    float: left;
    > ul {
        list-style: none;
        margin: 0;
        padding-left: 1em;
        margin-bottom: -4px;
        height: 47px;
        > li {
          height: 100%;
          float: left;
        }
    }
  }
}


.main-menu {
  .st();
  line-height: 27px;
  float: right;
  display: block;
  padding: @button-padding-top 15px @button-padding-top 10px;
  border: 1px solid lighten(@color-masthead, 10%);
  .border-radius(0, 0, 7px, 7px);
  color: @text-color-inverse;
  margin: 5px 0;
  width: auto;
  height: auto;
  background-size: 32px;
  background-position: right;

  &:after {
    content: "";
    .icon_menu_inv();
    display: inline-block;
    width: 26px;
    height: 25px;
    float: right;
  }

  &:hover {
    text-decoration: none;
    background-color: lighten(@color-masthead, 5%);
    color: @text-color-inverse;
  }

  @media (min-width: @screen-xs) {
    margin: 3px 0;
  }

}

.support-actions {
  .st();
  padding: 0;
  line-height: 27px;
  float: right;
  width: 100%;
  position: relative;
  list-style: none;
  margin: 0;

  @media (min-width: @screen-xs) {
    width: auto;
    padding: 1px 1em;
  }

  li {
    float: left;
    position: relative;
    left: 100%;
    width: 50%;

    @media (min-width: @screen-xs) {
      width: auto;
      left: auto;
    }

    a {
      color: @text-color-inverse;
      display: block;
      border: 1px solid lighten(@color-masthead, 10%);
      margin: 3px 0;
      padding: @button-padding-top 2em;
      .border-radius(7px, 7px, 7px, 7px);
      &:hover,
      &:focus {
        background-color: @black;
        text-decoration: none;
      }

      @media (min-width: @screen-xs) {
        background-color: @yellow;
        color: @text-color;
        border: none;
        &:hover,
        &:focus {
          background-color: @yellow-dark;
        }
      }

    }
  }
  #donate, #feedback {
    margin-left: -100%;
    text-align: right;
    a {
      margin-right: 5px;
      float: right;
    }
  }
  #get-involved {
    margin-left: -50%;
    a {
      margin-left: 5px;
      float: left;
    }
  }
  #donate, #get-involved, #feedback {
    @media (min-width: @screen-xs) {
      margin-left: 0;
    }
  }
}
