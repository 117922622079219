#search-menu {
  form {
    margin: 0;
  }
}

.template-search #content-core .tags  {
  .grid-center();
  .st();
  box-sizing: border-box;
  padding: .5em .7em;
  border: 1px solid @grey-5;
  margin-bottom: 36px;
  ul {
    display: inline-block;
    .listByline();
    > li {
      float: left;
      margin: 0;
      background-color: transparent;
      > a {
        padding-right: 5px;
        color: @grey-3;
        &:hover {
          color: @black;
          background-color: transparent;
        }
        > span {
          padding: 0;
        }
      }
      .category-topics:before {
        .icon_topic(10px);
      }
      .category-countries:before{
        .icon_country(10px);
      }
      .category-document:before {
        .icon_document(10px);
      }
    }
  }
}

#reset-filter-label {
  color: @menu-labels !important;
  cursor: pointer;
  &:hover {
    background-color: @menu-dark-soft !important;
  }
  &:before {
    margin-right: 13px;
    margin-left: 11px;
  }
}

#search-box {
  padding-top: 20px;
  padding-bottom: 40px;
  .clearfix();
}
.search-input {
  .st();
  display: block;
  float: left;
  padding: 7px 1em 8px 1em;
  margin-left: 1em;
  box-sizing: border-box;
  border: 1px;
  &:focus {
    color: @black;
    outline: none;
  }
}
.search-button {
  display: block;
  float: left;
  padding: 0;
  border: 0;
  height: 36px;
}
.search-icon {
  .icon_serach_inv();
  display: inline-block;
  height: 36px;
  width: 36px;
  background-size: 32px;
  background-position: center;
  background-color: @grey-2;
}

.heading-filter {
  .t6();
  background-position: 10px 0;
  padding: 5px 0 0 52px;
  color: @menu-labels;
}

.heading-with-icon() {
  background-size: 32px;
  background-position: 48px 14px;
  height: auto;
  width: auto;
  .t6();
  color: @white;
  margin: 0;
  padding: 19px 60px 19px 80px;
  border-top: 1px solid @menu-dark-borderline;
}
.badge {
  .st();
  position: absolute;
  margin: 0;
  top: 50%;
  right: 20px;
  display: block;
  min-width: 30px;
  height: 25px;
  background-color: @red;
  color: @white;
  border-radius: 2px;
  line-height: 23px;
  text-align: center;
  margin-top: -12.5px;
  padding: 0;

  &:empty {
    display: none;
  }
}
.heading-topics {
  .icon_topic();
  .heading-with-icon();
}
.heading-document {
  .icon_document();
  .heading-with-icon();
}
.heading-countries {
  .icon_country();
  .heading-with-icon();
}
.subheading {
  .st();
  color: white;
  padding: 19px 60px 19px 80px;
  margin: 0;
  font-weight: normal;
  border-top: 1px solid @menu-dark-borderline;
}

#filter-filter-title {
  > span:before {
    .icon_tag_md2();
  }
}

#filter-reset-filter-level1-btn {
  > a:before {
    .icon_reset_a_md2();
  }
}

#filter-reset-filter-level2-btn {
  > label:before {
    .icon_reset_a_soft();
  }
}

#filter-topic-subjects > a:not(.expand) {
  &:before {
    .icon_topic();
  }
}

#filter-topic-documents  > a:not(.expand) {
  &:before {
    .icon_document();
  }
}

#filter-topic-countries  > a:not(.expand) {
  &:before {
    .icon_country();
  }
}

#filter-topic-period  > a:not(.expand) {
  &:before {
    .icon_timespan();
  }
}

#search-menu {


  .menuitem {
    div {
      white-space: nowrap;
    }
    label {
      .st();
      color: @grey-1;
      display: block;
      padding: 19px 5px 19px 5px;
      width: 100%;
      box-sizing: border-box;
      text-overflow: ellipsis;
      overflow: hidden;
      &:before {
        content: "";
        .icon_checkbox_a_soft();
        display: inline-block;
        width: 32px;
        height: 32px;
        vertical-align: middle;
      }
      &:hover {
        background-color: @menu-bright-hover;
      }
    }
  }

  .clear-filter {
    + label:before {
      .icon_reset_a_soft();
      display: inline-block;
    }
  }

  input[type="checkbox"], input[type="button"] {
    opacity: 0;
    position: absolute;
    &:checked {
      + label {
        &:before {
          .icon_checkbox_b_dark();
          display: inline-block;
        }

        &:hover:before {
          .icon_reset_b_dark();
          display: inline-block;
        }
      }
    }
  }
}
