.menu-wrapper > .menu ul > .menuitem.menu-close > a,
.submenu-close {
  .icon-close-soft-2;
}

#search-menu .clear-filter + label:before {
  .icon-reset-a-soft-2;
}

footer section {
  input[type='email'] {
    color: @ai-blue-extra-3;
    border-color: @ai-blue-extra-4;
  }

  input[type='submit'] {
    background-color: @ai-blue-extra-2;
    border-color: @ai-blue-extra-2;
  }
}

.search-input {
  color: @ai-blue-extra-3;
  border-color: @ai-blue-extra-1;
}

.search-icon {
  background-color: @ai-blue-extra-1;
  border-color: @ai-blue-extra-1;
}

footer section .row.bottom > .right {
  background-image: url("++theme++amnesty/images/logo_footer_extranet.svg");
}

&.template-search,
&.template-list_view:not(.portaltype-amnesty-extranet-blog),
&.template-events_listing,
&.template-magazine_listing,
&.template-view.portaltype-amnesty-web-magazine {
  #content-core ul > li {
    @media (min-width: @screen-lg) {
      width: ~"calc(100% - @{thumbnail-width})";
    }

    > a > div {
      padding: @xs-box-top @xs-box-right @xs-box-bottom @xs-box-left;
      @media (min-width: @screen-xs) {
        float: none;
        padding: @xs-box-top @xs-box-right @xs-box-bottom @xs-box-left;
      }
      @media (min-width: @screen-sm) {
        padding: @sm-box-top @sm-box-right @sm-box-bottom @sm-box-left;
      }
      @media (min-width: @screen-md) {
        padding: @md-box-top @md-box-right @md-box-bottom @md-box-left;
      }
      @media (min-width: @screen-lg) {
        padding: @lg-box-top @lg-box-right @lg-box-bottom @lg-box-left;
      }
      @media (min-width: @screen-xl) {
        padding: @xl-box-top @xl-box-right @xl-box-bottom @xl-box-left;
      }
    }
  }
}

#edit-zone {
  // Use important due to /amnesty.webtheme/amnesty/webtheme/theme/less/content.less
  display: none !important;

  @media screen and (min-width: 768px) {
    // Use specific min-width due to it being set to this width by plone and not our theme
    display: block !important;
  }
}

li#plone-contentmenu-workflow > span {
  // Hide status if the user doesn't have the permission to change it
  display: none;
}
