#content {
  .grid-center;
}

#profile-list-container {
  .grid-border();
}

#profile-list-letters {
  ul {
    .list;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 20px;

    li {
      flex-basis: 100% / 13; // There are 26 letters and we want two rows so we divide by 13
      min-height: 40px;
      box-sizing: border-box;

      &.available {
        border: 1px solid @grey-5;

        a {
          cursor: pointer;
        }

        &.active a,
        a:hover,
        a:focus {
          background-color: @grey-5;
          color: @black;
        }
      }

      a {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: @text-color-soft;
        cursor: default;

        &:hover, &:focus {
          text-decoration: none;
        }

        span {
          display: flex;
          justify-content: center;
          flex-direction: row;
        }
      }
    }
  }
}

#profile-list {
  .title {
    border-top: 1px solid @grey-5;
    position: relative;
    cursor: pointer;

    .letter {
      .t6;
      display: inline-block;
      color: @text-color-soft;
      padding-bottom: 20px;
    }

    .letter-toggler {
      position: absolute;
      right: 3px;
      top: 21px;

      display: inline-block;
      width: 10px;
      height: 10px;
      border: solid @text-color-soft;
      border-width: 3px 3px 0 0;
      transform: rotate(-45deg);

      &:hover {
        border-color: @black;
      }
    }
  }

  .items .item {
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;

    > a {
      @media (min-width: @screen-sm) {
        display: flex;
        flex-direction: row;
      }

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    .user-image {
      @media (min-width: @screen-sm) {
        flex-basis: auto;
        margin-right: 20px;

        img {
          width: 120px;
          height: 120px;
        }
      }

      img {
        border-radius: 100%;
        margin: auto;
        display: block;
      }
    }

    .user-info {
      @media (min-width: @screen-sm) {
        flex-basis: 80%;
      }

      h3, p {
        padding-top: 0;
        color: @black;
      }

      p {
        .bt2;
      }
    }
  }
}
