#edit-zone {
  display: block !important;
}

#content {

  margin-bottom: 3em;

  .documentFirstHeading {
    .grid-border();
    .title {
      padding-top: 0;
    }
  }
}

.documentByLine {
  .st();
  padding-bottom: 0.5em;
  color: @grey-3;
  .clearfix();
  .grid-border();
}
.documentDescription {
  .lt();
  .grid-border();
}

#content-core {
  .clearfix();
}

.leadImage {
	margin: 0;
	position: relative;
	background-color: @grey-1;
	width: 100%;
    text-align: center;
    
    > img {
        max-height: 742px;
        width: auto;
    }

  .leadImageCaption {
    .st();
    width: 100%;
    box-sizing: border-box;
    color: @black;
    background: @body-bg;
    padding-top: 10px;
    text-align: left;
    .grid-border();
  }
  .captionWrapper {
    display: block;
    max-width: @gridsystem-width;
    margin: 0 auto;
  }
  > a {
    display: block;
    position: relative;
  }
}

.captionCopyright {
  color: @grey-3;
}
.responsiveIframe {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 60%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.responsiveIframeLeft {
  position: relative;
  float: left;
  width: 350px;
  height: auto;
  aspect-ratio: 2 / 3;

  iframe {
    position: relative;
    height: 100%;
  }
}

.anchor-list {
  .clearfix();
  border: 1px solid @grey-5;
  border-left: 0;

  .anchor-link {
    .st();
    display: block;
    float: left;
    color: @grey-4;
    padding: 20px 0;
    min-width: 40px;
    line-height: 0;
    text-align: center;
    border-left: 1px solid @grey-5;
    &:hover,
    :focus,
    :active {
      background: @grey-5;
      color: @black;
      text-decoration: none;
    }
  }
}

.plone-modal-close {
  font-size: 60px;
  margin-top: -20px;
}

.image-inline.captioned .imageCaption {
  .st();
  display: block;
}
