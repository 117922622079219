.faq .content {
    overflow-y: visible;
    height: auto;
}

.faq > input[type="checkbox"]:checked ~ .content {
    height: 0;
    overflow: hidden;
}


.faq label {
    display: block;
    cursor: pointer;
    z-index: 1;
    position: relative;
    padding-right: 30px;
}

.faq .handle {
    border-top: 1px solid @grey-4;
    padding: 30px 0;
    display: block;
    position: relative;
    &:after {
        display: block;
        content: " ";
        width: 30px;
        height: 30px;
        position: absolute;
        right: 0;
        top: 32px;
        z-index: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMThweCIgaGVpZ2h0PSIxOHB4IiB2aWV3Qm94PSIwIDAgMTggMTgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+aWNvbl9taW51czwvdGl0bGU+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iaWNvbl9taW51cyI+CiAgICAgICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwtNDIiIHN0cm9rZT0iIzIyMjIyMiIgc3Ryb2tlLXdpZHRoPSIyIiBvcGFjaXR5PSIwIiBjeD0iOSIgY3k9IjkiIHI9IjkiPjwvY2lyY2xlPgogICAgICAgICAgICA8cG9seWdvbiBpZD0iU2hhcGUiIGZpbGw9IiMyMjIyMjIiIGZpbGwtcnVsZT0ibm9uemVybyIgcG9pbnRzPSI0LjUgOS43NSA0LjUgOC4yNSAxMy41IDguMjUgMTMuNSA5Ljc1Ij48L3BvbHlnb24+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=);
    }
}

.faq > input[type="checkbox"]:checked ~ .handle {
    &:after {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTlweCIgaGVpZ2h0PSIxOXB4IiB2aWV3Qm94PSIwIDAgMTkgMTkiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+aWNvbl9wbHVzPC90aXRsZT4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJpY29uX3BsdXMiPgogICAgICAgICAgICA8Y2lyY2xlIGlkPSJPdmFsLTQyIiBzdHJva2U9IiMyMjIyMjIiIHN0cm9rZS13aWR0aD0iMiIgb3BhY2l0eT0iMCIgY3g9IjkuNSIgY3k9IjkuNSIgcj0iOS41Ij48L2NpcmNsZT4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlNoYXBlIiBmaWxsPSIjMzYyQzJDIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHBvaW50cz0iMTQuMjUgMTAuMjkxNjY2NyAxMC4yOTE2NjY3IDEwLjI5MTY2NjcgMTAuMjkxNjY2NyAxNC4yNSA4LjcwODMzMzMzIDE0LjI1IDguNzA4MzMzMzMgMTAuMjkxNjY2NyA0Ljc1IDEwLjI5MTY2NjcgNC43NSA4LjcwODMzMzMzIDguNzA4MzMzMzMgOC43MDgzMzMzMyA4LjcwODMzMzMzIDQuNzUgMTAuMjkxNjY2NyA0Ljc1IDEwLjI5MTY2NjcgOC43MDgzMzMzMyAxNC4yNSA4LjcwODMzMzMzIj48L3BvbHlnb24+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=);
    }
}


.faq:last-child {
    border-bottom: 1px solid @grey-4;
}


.faq > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}

body#tinymce .faq {
    .content {
        border: 1px dashed grey;
        min-height: 1rem;
    }
    > input[type="checkbox"]:checked ~ .content {
        height: auto;
        overflow: show;
    }
}
