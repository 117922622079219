@path: "../../theme/less/";

@import "@{path}variables.less";
@import "variables.less";

@import "@{path}mixins.less";
@import "@{path}normalize.less";
@import "@{path}columns.less";
@import "@{path}sprites.less";
@import "@{path}fonts.less";
@import "@{path}typography.less";
@import "@{path}tinymce.less";
@import "@{path}form.less";
@import "@{path}sharing.less";
@import "@{path}simplelayout.less";
@import "@{path}alerts.less";
@import "@{path}breadcrumbs.less";
@import "@{path}tabs.less";
@import "@{path}gallery.less";
@import "@{path}photoswipe.less";

@import "@{path}scaffolding.less";
@import "@{path}header.less";
@import "@{path}dropdown.less";
@import "@{path}carousel.less";
@import "@{path}carousel_light.less";
@import "@{path}batching.less";
@import "@{path}print.less";

@import "@{path}content.less";
@import "@{path}portalpage.less";
@import "@{path}donation.less";
@import "@{path}article.less";
@import "@{path}listview.less";
@import "@{path}portlet.less";
@import "@{path}footer.less";
@import "@{path}country_listing.less";
@import "@{path}formview.less";
@import "@{path}thankspage.less";

@import "@{path}menu.less";
@import "@{path}navigation.less";
@import "@{path}filter.less";
@import "@{path}overlay.less";

@import "@{path}teaserblock.less";
@import "@{path}collectionblock.less";
@import "@{path}downloadsblock.less";
@import "@{path}rssblock.less";
@import "@{path}textblock.less";
@import "@{path}megateaser.less";
@import "@{path}related_items.less";
@import "@{path}filelisting.less";
@import "@{path}overline_tag.less";
@import "@{path}issuu.less";
@import "@{path}cookiebot.less";
@import "@{path}faq.less";

@import (less) "@{path}photoswipe.css";
@import (less) "@{path}photoswipe.theme.default.css";
@import (less) "@{path}select.css";
@import (less) "@{path}slick.css";

.extranet {
  @import "mixins.less";
  @import "extranet.less";
  @import "discussion.less";
  @import "login_form.less";
  @import "header.less";
  @import "footer.less";
  @import "collectionblock.less";
  @import "teaserblock.less";
  @import "related_items.less";
  @import "listview.less";
  @import "carousel_light.less";
  @import "latest-article-collection.less";
  @import "overline_tag.less";

  &.template-view.portaltype-amnesty-extranet-userprofile {
    @import "user-profile.less";
  }
  &.template-userprofile_list {
    @import "user-profile-list.less";
  }
  &.template-registration, &.template-confirm-email {
    @import "registration.less";
  }
  &.template-comment-listing {
    @import "comment_listing.less";
  }
  &.portaltype-amnesty-extranet-blogarticle {
    @import "blog-article.less";
  }

  &.template-passwordreset,
  &.template-login_failed,
  &.template-mail_password_response,
  &.template-amnesty-extranet-blogarticle,
  &.template-insufficient_privileges,
  &.template-login_success,
  &.template-user-migration,
  &.template-new-profile {
    #content {
      .grid-center;
    }
  }
}
