.sl-layout-dragging .sl-simplelayout {
  float: none;
}
.sl-simplelayout {
  display: block;
  .grid-center();
}

.sl-layout {
  .clearfix();
  position: relative;
  @media (min-width: @screen-xs) {
    margin-top: @gutter-width;
  }
}

.sl-block {
  width: 100%;
}

.sl-block-content p:last-child {
  margin-bottom: 0;
  padding-bottom: 1em;
}

.sl-column {
  width: 100%;
  margin-right: 0;
  margin-bottom: @gutter-width;
  float: left;
  min-height: 50px;
}

.sl-col-2 {
  @media (min-width: @screen-xs) {
    margin-bottom: 0;
    width: 100% * (6 * @column-width + 5 * @gutter-width) / @gridsystem-width;
    margin: 0;
    margin-left: 100% * @gutter-width / @gridsystem-width;
    float: left;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: -1;
      width: 100% * (6 * @column-width + 5 * @gutter-width) / @gridsystem-width;
      background-color: @white;
      transition: background-color .1s ease-out;
    }
    &:hover:before {
      background-color: @grey-5;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}

// Make sure block overlays are behind .plone-modal stuff.
.overlay[style] {
  z-index: 999 !important;
}
#exposeMask[style] {
  z-index: 998 !important;
}

.sl-toolbox {
  width: 230px;
}

.NOTYET-sl-toolbox {
  .components {
    a.sl-toolbox-header {
      padding: 0;
      &.components > i {
        width: 50px;
        height: 50px;
        .icon_blocks(50px);
      }
      &.layouts > i {
        width: 50px;
        height: 50px;
        .icon_layouts(50px);
      }
    }
    .sl-toolbox-components .sl-toolbox-component {
      padding: 0;
      width: 50px;
      height: 50px;
      > .description {
        margin-right: 5px;
        margin-top: 10px;
      }
      &:hover {
        > .description {
          margin-right: 5px;
        }
      }
      > i {
        height: 50px;
        width: 50px;
        float: right;
        &.icon-amnesty-web-collectionblock {
          .icon_collectionblock(50px);
          &:hover {
            .icon_collectionblock_inv(50px);
          }
        }
        &.icon-amnesty-web-downloadblock {
          .icon_downloadblock(50px);
          &:hover {
            .icon_downloadblock_inv(50px);
          }
        }
        &.icon-formfolder {
          .icon_formblock(50px);
          &:hover {
            .icon_formblock_inv(50px);
          }
        }
        &.icon-amnesty-web-rssblock {
          .icon_rssblock(50px);
          &:hover {
            .icon_rssblock_inv(50px);
          }
        }
        &.icon-amnesty-web-teaserblock {
          .icon_teaserblock(50px);
          &:hover {
            .icon_teaserblock_inv(50px);
          }
        }
        &.icon-amnesty-web-textblock {
          .icon_textblock(50px);
          &:hover {
            .icon_textblock_inv(50px);
          }
        }
      }
    }
  }
}
