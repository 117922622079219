@file-icon-height: 100px;

.template-file_listing {

  #content-core ul > [class^='filetype-'] > a > div {
    padding: @xs-box-top @xs-box-right @xs-box-bottom @xs-box-left;
    min-height: @xs-box-top + @file-icon-height + @xs-box-bottom;
    @media (min-width: @screen-xs) {
      float: none;
      padding: @xs-box-top @xs-box-right @xs-box-bottom @xs-box-left + @file-icon-height;
      min-height: @xs-box-top + @file-icon-height + @xs-box-bottom;
    }
    @media (min-width: @screen-sm) {
      padding: @sm-box-top @sm-box-right @sm-box-bottom @sm-box-left + @file-icon-height;
      min-height: @sm-box-top + @file-icon-height + @sm-box-bottom;
    }
    @media (min-width: @screen-md) {
      padding: @md-box-top @md-box-right @md-box-bottom @md-box-left + @file-icon-height;
      min-height: @md-box-top + @file-icon-height + @md-box-bottom;
    }
    @media (min-width: @screen-lg) {
      padding: @lg-box-top @lg-box-right @lg-box-bottom @lg-box-left + @thumbnail-width;
      min-height: @lg-box-top + @file-icon-height + @lg-box-bottom;
    }
    @media (min-width: @screen-xl) {
      padding: @xl-box-top @xl-box-right @xl-box-bottom @xl-box-left + @thumbnail-width;
      min-height: @xl-box-top + @file-icon-height + @xl-box-bottom;
    }
  }

  .file-size {
    position: absolute;
    left: (@file-icon-height / 2);
    color: @grey-2;
    .translate3d(-50%);
    display: none;
    @media (min-width: @screen-xs) {
      display: block;
      margin-top: @xs-box-top + @file-icon-height;
    }
    @media (min-width: @screen-sm) {
      margin-top: @sm-box-top + @file-icon-height;
    }
    @media (min-width: @screen-md) {
      margin-top: @md-box-top + @file-icon-height;
    }
    @media (min-width: @screen-lg) {
      margin-top: @lg-box-top + @file-icon-height;
      left: (@thumbnail-width / 2)
    }
    @media (min-width: @screen-xl) {
      margin-top: @xl-box-top + @file-icon-height;
    }
  }

  .filetype-doc > a {
    @media (min-width: @screen-xs) {
      .icon_download_doc_sft(@file-icon-height);
      &:hover {
        .icon_download_doc_drk(@file-icon-height);
      }
    }
  }

  .filetype-pdf > a {
    @media (min-width: @screen-xs) {
      .icon_download_pdf_sft(@file-icon-height);
      &:hover {
        .icon_download_pdf_drk(@file-icon-height);
      }
    }
  }

  .filetype-xls > a {
    @media (min-width: @screen-xs) {
      .icon_download_xls_sft(@file-icon-height);
      &:hover {
        .icon_download_xls_drk(@file-icon-height);
      }
    }
  }

  .filetype-default > a {
    @media (min-width: @screen-xs) {
      .icon_download_file_sft(@file-icon-height);
      &:hover {
        .icon_download_file_drk(@file-icon-height);
      }
    }
  }

  [class^='filetype-'] {
    > a {
      position: relative;
      background-position: (@file-icon-height / 2) - (@file-icon-height / 2) @xs-box-top;
      @media (min-width: @screen-sm) {
        background-position: (@file-icon-height / 2) - (@file-icon-height / 2) @sm-box-top;
      }
      @media (min-width: @screen-md) {
        background-position: (@file-icon-height / 2) - (@file-icon-height / 2) @md-box-top;
      }
      @media (min-width: @screen-lg) {
        background-position: (@thumbnail-width / 2) - (@file-icon-height / 2) @lg-box-top;
      }
      @media (min-width: @screen-xl) {
        background-position: (@thumbnail-width / 2) - (@file-icon-height / 2) @xl-box-top;
      }
    }
  }
}
