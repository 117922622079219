.template-country_listing {
  #content {
    padding: 0;
    margin: 0;
    max-width: none;
  }
  #content-core {
    @media (min-width: @screen-md) {
      max-width: @gridsystem-width;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .documentFirstHeading {
    @media (min-width: @screen-md) {
      max-width: @gridsystem-width;
      margin-left: auto;
      margin-right: auto;
    }
  }
  #viewlet-below-content-title {
    @media (min-width: @screen-md) {
      max-width: @gridsystem-width;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .tab-pane {
    .col {
      float: left;
      &:first-of-type {
        margin-left: 0;
      }
      @media (min-width: @screen-xs) {
        width: 100% * (6 * @column-width + 5 * @gutter-width) / @gridsystem-width;
        margin-left: 100% * @gutter-width / @gridsystem-width;
        &:last-of-type {
          margin-left: 0;
        }
      }
      @media (min-width: @screen-md) {
        width: 100% * (4 * @column-width + 3 * @gutter-width) / @gridsystem-width;
        margin-left: 100% * @gutter-width / @gridsystem-width;
        &:last-of-type {
          margin-left: 100% * @gutter-width / @gridsystem-width;
        }
      }
      > li > a {
        background-color: @white;
        &:hover {
          background-color: @body-bg;
        }
        @media (min-width: @screen-xs) {
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
