.support-actions {
  #logout,
  #login {
    margin-left: -50%;
    width: auto;

    @media (min-width: @screen-sm) {
      margin-left: auto;
      width: auto;
    }

    a {
      color: @text-color-inverse;
      display: block;
      border: 1px solid lighten(@color-masthead, 10%);
      margin: 3px 0;
      padding: @button-padding-top 2em;
      .border-radius(7px, 7px, 7px, 7px);

      &:hover,
      &:focus {
        background-color: @black;
        text-decoration: none;
      }

      @media (min-width: @screen-sm) {
        background: none;
        color: @text-color-inverse;
        border: 1px solid lighten(@color-masthead, 10%);

        &:hover,
        &:focus {
          background-color: @black;
        }
      }

    }
  }

  @media (max-width: @screen-sm) {
    .st();
    font-size: 15px / 100 * @mobile-font-size-factor !important;
    padding: 0;
    line-height: 27px;
    float: right;
    width: 100%;
    position: relative;
    list-style: none;
    margin: 0;

    li {
      float: left;
      position: relative;
      left: 100%;
      width: 50%;

      a {
        background: none;
        color: @text-color-inverse;
        display: block;
        border: 1px solid lighten(@color-masthead, 10%);
        margin: 3px 0;
        padding: @button-padding-top 2em;
        .border-radius(7px, 7px, 7px, 7px);

        &:hover,
        &:focus {
          background-color: @black;
          text-decoration: none;
        }
      }
    }

    display: flex;
    justify-content: center;

    #donate, #feedback, #get-involved, #logout, #login {
      margin: 0;
      left: auto;
      float: none;
      display: inline-block;
      width: auto;
    }
  }
}
