h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.overlay-headline-large {
  font-family: "TradeGothicW01-BoldCn20_675334";
  color: @black;
  font-size: 48px;
  line-height: 57px + 8px;

  @media (min-width: @screen-md) {
    font-size: 75px;
    line-height: 90px + 8px;
  }
}

.overlay-headline-small {
  font-family: "TradeGothicW01-BoldCn20_675334";
  color: @black;
  font-size: 24px;
  line-height: 30px + 8px;

  @media (min-width: @screen-md) {
    font-size: 38px;
    line-height: 46px + 8px;
  }
}

.overlay-leadtext {
  font-family: "TradeGothicW01-BoldCn20_675334";
  color: @white;
  font-size: 25px;
  line-height: 33px;
}

.t1() {
  font-family: "TradeGothicW01-BoldCn20_675334";
  font-size: 45px / 100 * @mobile-font-size-factor;
  line-height: 45px;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: normal;
  padding: 45px 0px 36px 0px;
  @media (min-width: @screen-xs) {
    font-size: 45px;
  }
}

.t2() {
  font-family: "TradeGothicW01-BoldCn20_675334";
  font-size: 33px / 100 * @mobile-font-size-factor;
  line-height: 33px;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: normal;
  padding: 33px 0 21px 0;
  @media (min-width: @screen-xs) {
    font-size: 33px;
  }
}

.t3() {
  font-family: "TradeGothicW01-BoldCn20_675334";
  font-size: 27px / 100 * @mobile-font-size-factor;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: normal;
  padding: 27px 0 18px 0;
  @media (min-width: @screen-xs) {
    font-size: 27px;
  }
}

.t4() {
  font-family: "TradeGothicW01-BoldCn20_675334";
  font-size: 21px / 100 * @mobile-font-size-factor;
  line-height: 21px;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: normal;
  padding: 21px 0 12px 0;
  @media (min-width: @screen-xs) {
    font-size: 21px;
  }
}

.t5() {
  font-family: "TradeGothicNextW01-HvCn_707049";
  font-size: 19px / 100 * @mobile-font-size-factor;
  line-height: normal;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0.665px;
  padding: 18px 0 0 0;
  @media (min-width: @screen-xs) {
    font-size: 19px;
  }
}

.t6() {
  font-family: "TradeGothicNextW01-HvCn_707049";
  font-size: 15px / 100 * @mobile-font-size-factor;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0.45px;
  line-height: 21px;
  padding: 12px 0 0 0;
  @media (min-width: @screen-xs) {
    font-size: 15px;
  }
}

.ol1() {
  font-family: "TradeGothicNextW01-Cond";
  font-size: 18px / 100 * @mobile-font-size-factor;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  line-height: 21px;
  padding: 45px 0 6px 0;
  @media (min-width: @screen-xs) {
    font-size: 18px;
  }
}

.ol2() {
  font-family: "TradeGothicNextW01-Cond";
  font-size: 18px / 100 * @mobile-font-size-factor;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  line-height: 21px;
  padding: 33px 0 3px 0;
  @media (min-width: @screen-xs) {
    font-size: 18px;
  }
}

.ol3() {
  font-family: "TradeGothicNextW01-Cond";
  font-size: 16px / 100 * @mobile-font-size-factor;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  line-height: 18px;
  padding: 27px 0 3px 0;
  @media (min-width: @screen-xs) {
    font-size: 16px;
  }
}

.ol4() {
  font-family: "TradeGothicNextW01-Cond";
  font-size: 16px / 100 * @mobile-font-size-factor;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1.1px;
  line-height: 18px;
  padding: 21px 0 3px 0;
  @media (min-width: @screen-xs) {
    font-size: 16px;
  }
}

.ol6() {
  font-family: "TradeGothicNextW01-Cond";
  font-size: 14px / 100 * @mobile-font-size-factor;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1.1px;
  line-height: 20px;
  padding: 12px 0px 0px 0px;
  @media (min-width: @screen-xs) {
    font-size: 14px;
  }
}

.mt() {
  font-family: "TradeGothicW01-BoldCn20_675334";
  font-size: 21px / 100 * @mobile-font-size-factor;
  line-height: 21px;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: normal;
  padding: 21px 0 12px 0;
  @media (min-width: @screen-xs) {
    font-size: 21px;
  }
}

.ul1() {
  margin: 21px 0 36px 21px;
  padding: 0;
  > li {
    padding-bottom: 12px;
    &:last-child {
      padding-bottom: 0;
    }
  }
}

.lt() {
  font-family: "TradeGothicNextW01-Bold_693232";
  font-size: 25px / 100 * @mobile-font-size-factor;
  line-height: 33px;
  margin: 0;
  letter-spacing: 0.2px;
  padding: 0 0 90px 0;
  @media (min-width: @screen-xs) {
    font-size: 25px;
  }
}

.bt() {
  font-family: "Trade Gothic Next W01";
  font-size: 18px / 100 * @mobile-font-size-factor;
  line-height: 27px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: normal;
  padding: 0 0 18px 0;
  @media (min-width: @screen-xs) {
    font-size: 18px;
  }
}

.bt_strong() {
  font-family: "TradeGothicNextW01-Bold_693229";
  font-size: 18px / 100 * @mobile-font-size-factor;
  line-height: 27px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: normal;
  padding: 0 0 18px 0;
  @media (min-width: @screen-xs) {
    font-size: 18px;
  }
}

.bt_quote() {
  font-family: "TradeGothicNextW01-Bold_693232";
  font-size: 18px / 100 * @mobile-font-size-factor;
  line-height: 27px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: normal;
  padding: 0 0 18px 0;
  @media (min-width: @screen-xs) {
    font-size: 18px;
  }
}

.bt2() {
  font-family: "Trade Gothic Next W01";
  font-size: 16px / 100 * @mobile-font-size-factor;
  line-height: 21px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: normal;
  padding: 0 0 18px 0;
  @media (min-width: @screen-xs) {
    font-size: 16px;
  }
}

.pl1() {
  font-family: "TradeGothicNextW01-Bold_693232";
  font-size: 19px / 100 * @mobile-font-size-factor;
  line-height: 28px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0.2px;
  padding: 0 0 36px 0;
  @media (min-width: @screen-xs) {
    font-size: 19px;
  }
}

.pl2() {
  font-family: "TradeGothicNextW01-Bold_693232";
  font-size: 17px / 100 * @mobile-font-size-factor;
  text-transform: none;
  font-weight: normal;
  line-height: 21px;
  letter-spacing: 0.2px;
  padding: 0 0 36px 0;
  @media (min-width: @screen-xs) {
    font-size: 17px;
  }
}

.st() {
  font-family: "TradeGothicNextW01-Cond";
  font-size: 15px / 100 * @mobile-font-size-factor;
  text-transform: none;
  font-weight: normal;
  line-height: 21px;
  letter-spacing: 0.3px;
  padding: 0 0 18px 0;
  @media (min-width: @screen-xs) {
    font-size: 15px;
  }
}
