.template-donation_view {
  .portletRelated {
    background-color: #fff;
    .portletContent {
      border: none;
    }
    .delimiter {
      background-color: @body-bg;
      margin: 1em -1em;
    }
    .thermometer {
      background-color: #fff000;
      iframe {
        border: 0;
        overflow: hidden;
      }
    }
    .delimiterThermometer {
      background-color: @body-bg;
      margin: -1em -1em 1em -1em;
    }
  }
}
