#global_statusmessage {
  max-width: @gridsystem-width;
  margin: 0 auto;
}

.portalMessage {
	padding: 15px;
	padding-left: 45px;
	margin-bottom: 18px;
	border: 0;
	border-radius: 4px;
	box-shadow: 0 1px 2px rgba(0,0,0,.17);
	text-shadow: 0 1px rgba(255,255,255,.1);
	> strong, > dt {
		font-weight: bold;
		padding-right: 5px;
		color: @grey-1;
	}
	> strong:before, > dt:before { 
		// font-size:@plone-font-size-base; 
		// font-weight: @plone-font-weight-regular; 
		margin-left: -30px;
		margin-right: 10px;
		display: inline-block; 
		border-radius: 20px;
		text-align:center;
		color:rgba(255, 255, 255, 0.9); 
		width: 20px; 
		height: 20px;
		line-height: 20px; //IE fix
		text-shadow: 0 -1px rgba(0,0,0,.5);
		top: -1px;
		position: relative;
	}
	> dt, > dd { display: inline;}
	> dd {margin: 0;}
}

.portalMessage.info {
	background-color: lighten(@blue, 40%);
	border: 1px solid @blue;
	color: @text-color;
	& > strong:before, & > dt:before {background-color: @blue; content:"i";}
}

.portalMessage.warning {
	background-color: lighten(@orange, 40%);
	border: 1px solid @orange;
	color: @text-color;
	& > strong:before, & > dt:before {background-color: @orange; content:"!";}
}

.portalMessage.error {
	background-color: lighten(@red, 40%);
	border: 1px solid @red;
	color: @text-color;
	& > strong:before, & > dt:before {background-color: @red; content:"×";}
}