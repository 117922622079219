.collection {
  @media (min-width: @screen-md) {
    max-width: @gridsystem-width;
    margin: 1em auto 0 auto;
  }
  header {
    .grid-border();
    h2 {
      display: inline;
      padding-right: 1em;
      .t6();
    }
    a {
      .st();
    }
  }
  ul {
    .list();
    margin-top: 20px;
    position: relative;
    li {
      .teaser();
      margin-bottom: @gutter-width;
      @media (min-width: @screen-md) {
        margin-bottom: 0;
        width: 100% * (3 * @column-width + 2 * @gutter-width) / @gridsystem-width;
        margin: 0;
        margin-left: 100% * @gutter-width / @gridsystem-width;
        float: left;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          z-index: -1;
          width: 100% * (3 * @column-width + 2 * @gutter-width) / @gridsystem-width;
          background-color: @white;
          transition: background-color .1s ease-out;
        }
        &:hover:before {
          background-color: @grey-5;
        }
        &:first-child {
          margin-left: 0;
        }
      }
      > a {
        &.list-item {
          display: flex;

          @media (min-width: @screen-md) {
            display: block;;
          }
        }
        h2 {
          padding: 0;
          .title {
            .t6();
            padding-top: 0;
          }
          .overline {
            .ol6();
            padding-top: 0;
          }
        }
        time {
          .st();
          color: @grey-3;
        }
        .image-wrapper {
          align-self: flex-start;
          max-width: 96px;
          flex: none;
          @media (min-width: @screen-sm) {
            max-width: 150px;
          }
          @media (min-width: @screen-md) {
            max-width: 100%;
          }

          &.video {
            position: relative;
            .video-marker();
          }
        }
        img {
          max-width: 96px;
          @media (min-width: @screen-sm) {
            max-width: 150px;
          }
          @media (min-width: @screen-md) {
            max-width: 100%;
          }
        }
        div {
          max-width: ~"calc( 100% - 96px )";
          padding-left: 96px + @xs-box-left;
          @media (min-width: @screen-sm) {
            max-width: ~"calc( 100% - 150px )";
            padding-left: 150px + @sm-box-left;
          }
          @media (min-width: @screen-md) {
            max-width: none;
            padding-left: @md-box-left;
          }
          @media (min-width: @screen-lg) {
            padding-left: @lg-box-left;
          }
          @media (min-width: @screen-xl) {
            padding-left: @xl-box-left;
          }
        }
      }
    }
  }
}
