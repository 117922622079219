.sharing(@color: dark) {
  ul {
    .list();
    .listTable();
    > li {
      width: 25%;
      > a {
        background-position: 50%;
        height: 3em;
        width: 100%;
        &:hover when (@color = dark) {
          background-color: @grey-5;
        }
      }
      &.facebook > a when (@color = dark) {
        .icon_facebook_soft();
        &:hover {
          .icon_facebook_dark();
        }
      }
      &.facebook > a when (@color = light) {
        .icon_facebook_inv();
      }
      &.googleplus > a when (@color = dark) {
        .icon_google_plus_soft();
        &:hover {
          .icon_google_plus_dark();
        }
      }
      &.googleplus > a when (@color = light) {
        .icon_google_plus_dark_round();
      }
      &.twitter > a when (@color = dark) {
        .icon_twitter_soft();
        &:hover {
          .icon_twitter_dark();
        }
      }
      &.twitter > a when (@color = light) {
        .icon_twitter_inv();
      }
      &.bluesky > a when (@color = light) {
        .icon_bluesky_inv();
      }
      &.mail > a when (@color = dark) {
        .icon_email_soft();
        &:hover {
          .icon_email_dark();
        }
      }
      &.mail > a when (@color = light) {
        .icon_email_inv();
      }
      &.youtube > a when (@color = dark) {
        .icon_youtube_soft();
        &:hover {
          .icon_youtube_dark();
        }
      }
      &.youtube > a when (@color = light) {
        .icon_youtube_inv();
      }
      &.instagram > a when (@color = dark) {
        .icon_instagram_soft();
        &:hover {
          .icon_instagram_dark();
        }
      }
      &.instagram > a when (@color = light) {
        .icon_instagram_inv();
      }
      &.linkedin > a when (@color = dark) {
        .icon_linkedin_soft();
        &:hover {
          .icon_linkedin_dark();
        }
      }
      &.linkedin > a when (@color = light) {
        .icon_linkedin_inv();
      }
      > a > .share_text {
        display: none;
      }
    }
  }
}
