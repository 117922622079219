@media print {
  #masthead, #edit-zone, #portal-breadcrumbs, #footer, .portletSharing, #sl-toolbox, .sharing, [class^="sl-toolbar"] {
    display: none !important;
  }
  body {
    padding: 0 !important;
  }

  * {
    background-color: @white !important;
    -webkit-text-size-adjust: none !important;
  }

  a, a:hover, a:visited, a:active {
    color: @link-color;
    text-decoration: none !important;
  }

  img {
    filter: none !important;
    -webkit-filter: none !important;
    transform: scale(1) !important;
  }

  .sl-col-2 {
    width: 100% * (6 * @column-width + 5 * @gutter-width) / @gridsystem-width !important;
    margin-left: 100% * @gutter-width / @gridsystem-width !important;
    &:first-child {
      margin-left: 0 !important;
    }
  }

  @page {
    size: 22cm 28cm portrait;
    margin: 2.5cm 2.5cm 2cm;
    orphans: 8;
    widows: 4;
  }

}

@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

