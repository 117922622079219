.portlet {
  margin-bottom: 2em;
}
.portletHeader {
  .t6();
  color: @black;
  padding-top: 0;
  margin: 0 0 1em 0;
  .grid-border();
}

.portletContent {
  .st();
  padding: 1em;
  border: 1px solid @grey-5;
  p {
    margin: 0;
  }

  hr {
    border: 1px solid @grey-5;
    border-bottom: 0;
  }
}

.tags  {
  ul {
    display: inline-block;
    .listByline();
    > li {
      float: left;
      > a {
        padding-right: 5px;
        color: @grey-3;
        &:hover {
          color: @black;
        }
      }
      .category-topics:before {
        .icon_topic(10px);
      }
      .category-countries:before{
        .icon_country(10px);
      }
      .category-document:before {
        .icon_document(10px);
      }
    }
  }
}

.portletDownloads {
  .downloadsList();
  > .portletHeader {
    .grid-border();
  }
}

.portletSharing {
  .portletContent {
    padding: 0;
  }
  .sharing();
}
