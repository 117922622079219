@thumbnail-width: 310px;

#results-count {
  .ol2();
  padding: 15px 20px;
}

#adjust-filter {
  .st();
  color: @grey-3;
  padding: 15px 0;
  cursor: pointer;
  &:after {
    content: "›";
    margin-left: 3px;
  }
  &:hover {
    color: @black;
  }
}

.results-options {
  box-sizing: border-box;
  border: 1px solid @grey-5;
  margin-bottom: 36px;
  margin-top: 48px;
  .clearfix();
  span, a {
    .t6();
    float: left;
    padding: 0 5px 0 0;
    text-align: center;
  }
  .sorting-options {
    float: right;
    padding: 0;
    span, a {
      padding: 15px 20px;
      border-left: 1px solid @grey-5;
      &.title {
        background-color: transparent;
        border: none;
        .ol2();
        padding: 15px 20px;
      }
    }
    span {
      background-color: @grey-5;
    }
    a:hover {
      background-color: @grey-5;
      text-decoration: none;
    }
  }
}

.template-search,
.template-list_view,
.template-events_listing,
.template-file_listing,
.template-reminders,
.template-magazine_listing,
.template-view.portaltype-amnesty-web-magazine {
  .documentFirstHeading {
    .t1();
  }
  .listingBar, .results-options {
    .grid-center();
  }
  #content {
    header {
      .grid-center();
    }
  }
  #content-core {
    ul {
      .grid-center();
      .list();
      display: block;
      > li {
        // Reset list style for ul within the content area.
        ul {
          list-style-type: disc;
          list-style-position: inside;
          > li {
            margin: 0;
          }
        }
        .teaser();
        margin-bottom: 30px;
        time {
          .bt2();
          color: @grey-3;
          &+.description {
            .separatorLine();
          }
        }
        > a, .list-item {
          max-width: 100%;
          > .image-wrapper {
            float: left;
            > img {
              float: left;
              width: 100%;
              @media (min-width: @screen-xs) {
                max-width: @thumbnail-width;
              }
            }
          }
          > div {
            float: left;
            padding: @xs-box-top @xs-box-right @xs-box-bottom @xs-box-left;
            @media (min-width: @screen-xs) {
              float: none;
              padding: @xs-box-top @xs-box-right @xs-box-bottom @xs-box-left + @thumbnail-width;
            }
            @media (min-width: @screen-sm) {
              padding: @sm-box-top @sm-box-right @sm-box-bottom @sm-box-left + @thumbnail-width;
            }
            @media (min-width: @screen-md) {
              padding: @md-box-top @md-box-right @md-box-bottom @md-box-left + @thumbnail-width;
            }
            @media (min-width: @screen-lg) {
              padding: @lg-box-top @lg-box-right @lg-box-bottom @lg-box-left + @thumbnail-width;
            }
            @media (min-width: @screen-xl) {
              padding: @xl-box-top @xl-box-right @xl-box-bottom @xl-box-left + @thumbnail-width;
            }
          }
        }
        &[href]:hover {
          background-color: @grey-5;
        }
      }
    }
  }
}
