.portaltype-amnesty-web-article,
.portaltype-amnesty-extranet-blogarticle,
.portaltype-amnesty-web-event,
.portaltype-amnesty-web-thankspage,
.portaltype-amnesty-web-magazine {
  .leadImage {
    @media (min-width: @screen-lg) {
      margin-top: 45px;
    }
  }
  #content {
    header {
      .grid-center();
    }
  }
  #article-content {
    .grid-center();
    .grid-border();
    .clearfix;

    &.full-width-article {
      @media (min-width: @screen-md) {
        width: 100%;

        #article-body {
          width: 100% * ((@column-width + @gutter-width) * 8 - @gutter-width) / @gridsystem-width;
          margin: 0;
          position: static;
        }
      }
    }
  }
}

#magazine-body {
  .grid-center();
  .grid-border();
}

#article-body {
  img {
    max-width: 100%;
  }
  .image-left, .image-right {
    width: 100%;
    display: flex;
    margin: @xs-box-top 0 @xs-box-bottom 0;
    @media (min-width: @screen-xs) {
      width: auto;
      float: right;
      flex-direction: column;
      margin: 0 0 @sm-box-bottom @sm-box-left;
    }
    @media (min-width: @screen-sm) {
      margin: 0 0 @md-box-bottom @md-box-left;
    }
    @media (min-width: @screen-md) {
      margin: 0 0 @lg-box-bottom @lg-box-left;
    }
    @media (min-width: @screen-lg) {
      margin: 0 0 @xl-box-bottom @xl-box-left;
    }
    > .image-wrapper {
      flex-shrink: 0;
    }
    > .imageCaption {
      .st();
      display: inline-block;
      vertical-align: bottom;
      padding: 0 0 0 @xs-box-left;
      @media (min-width: @screen-xs) {
        padding: 0;
      }
      @media (max-width: @screen-xs) {
        width: auto !important;
      }
    }
  }
  ul {
    .ul1();
    > li {
      clear: left;
    }
  }
  p.smallText {
    margin-bottom: 18px;
  }
  ul.smallText {
    margin: 18px 0 18px 21px;
    li {
      margin-bottom: 9px;
    }
  }
}
.smallText {
  .st();
  padding-bottom: 0;
}

@media (min-width: @screen-md) {
  #article-body {
    padding: 0;
    position: relative;
    float: left;
    left: 100%;
    width: 100% * ((@column-width + @gutter-width) * 8 - @gutter-width) / @gridsystem-width;
    margin-left: 100% * ((@column-width + @gutter-width) * 4) / @gridsystem-width - 100%;
  }
  #article-byline {
    position: relative;
    float: left;
    left: 100%;
    width: 100% * (4 * @column-width + 3 * @gutter-width - @gutter-width) / @gridsystem-width;
    margin-left: -100%;
  }
  .template-donation_view, .template-membership_view {
    #article-body {
      margin-left: -100%;
    }
    #article-byline {
      margin-left: 100% * ((@column-width + @gutter-width) * 8) / @gridsystem-width - 100%;
    }
  }
}
