.select-select {
  display: none;
  /* For when we are on a small touch device and want to use native controls */
  pointer-events: none;
  position: absolute;
  opacity: 0; }

.select-element, .select-element:after, .select-element:before, .select-element *, .select-element *:after, .select-element *:before {
  box-sizing: border-box; }

.select-element {
  position: absolute;
  display: none; }
  .select-element.select-open {
    display: block; }

.select-theme-default, .select-theme-default *, .select-theme-default *:after, .select-theme-default *:before {
  box-sizing: border-box; }

.select.select-theme-default {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none; }
  .select.select-theme-default .select-content {
    border-radius: .25em;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    background: #fff;
    font-family: inherit;
    color: inherit;
    overflow: auto;
    max-width: 18rem;
    max-height: 18rem;
    -webkit-overflow-scrolling: touch; }
    @media (max-width: 27rem), (max-height: 27rem) {
      .select.select-theme-default .select-content {
        max-width: 11.25rem;
        max-height: 11.25rem; } }
  .select.select-theme-default .select-options {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    margin: 0;
    padding: 0; }
    .select.select-theme-default .select-options .select-option {
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      position: relative;
      list-style: none;
      margin: 0;
      line-height: 1.25rem;
      padding: 0.5rem 1em 0.5rem 2.5em;
      display: block;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .select.select-theme-default .select-options .select-option.select-option-selected:before {
        content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='-0.5 0 20 15'><rect fill='#444' stroke='none' transform='rotate(45 4.0033 8.87436)' height='5' width='6.32304' y='6.37436' x='0.84178'></rect><rect fill='#444' stroke='none' transform='rotate(45 11.1776 7.7066)' width='5' height='16.79756' y='-0.69218' x='8.67764'></rect></svg>");
        position: absolute;
        left: 1em;
        top: 0;
        bottom: .2em;
        height: 1em;
        width: 1em;
        margin: auto; }
      .select.select-theme-default .select-options .select-option:hover, .select.select-theme-default .select-options .select-option.select-option-highlight {
        background: #63a2f1;
        color: #fff; }
        .select.select-theme-default .select-options .select-option:hover.select-option-selected:before, .select.select-theme-default .select-options .select-option.select-option-highlight.select-option-selected:before {
          content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='-0.5 0 20 15'><rect fill='#fff' stroke='none' transform='rotate(45 4.0033 8.87436)' height='5' width='6.32304' y='6.37436' x='0.84178'></rect><rect fill='#fff' stroke='none' transform='rotate(45 11.1776 7.7066)' width='5' height='16.79756' y='-0.69218' x='8.67764'></rect></svg>"); }
      .select.select-theme-default .select-options .select-option:first-child {
        border-radius: 0.25em 0.25em 0 0; }
      .select.select-theme-default .select-options .select-option:last-child {
        border-radius: 0 0 0.25em 0.25em; }

.select-target.select-theme-default {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  border-radius: .25em;
  position: relative;
  padding: 0.5rem 3em 0.5rem 1em;
  background: #f6f6f6;
  border: .18em solid #ddd;
  cursor: pointer;
  color: #444;
  text-decoration: none;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }
  .select-target.select-theme-default:hover {
    border-color: #aaa;
    color: #000; }
  .select-target.select-theme-default.select-target-focused, .select-target.select-theme-default.select-target-focused:focus {
    border-color: #63a2f1;
    outline: none; }
  .select-target.select-theme-default b {
    position: absolute;
    right: 1em;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 1.25rem;
    width: 2em; }
    .select-target.select-theme-default b:before, .select-target.select-theme-default b:after {
      content: "";
      display: block;
      position: absolute;
      margin: auto;
      right: 0;
      height: 0;
      width: 0;
      border: .263em solid transparent; }
    .select-target.select-theme-default b:before {
      top: 0;
      border-bottom-color: inherit; }
    .select-target.select-theme-default b:after {
      bottom: 0;
      border-top-color: inherit; }