body {
  position: relative;
}

#offcanvas-wrapper {
  .hideBackface();
  -webkit-overflow-scrolling: touch;
  right: 0;
  width: 100%;
  position: relative;
  height: auto;
  min-height: 100%;
}

#inner-wrap {
  position: relative;
  .translate3d();
  .transition(transform, @menu-animation-duration);
}

#offcanvas-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50px;
  left: 0;
  background: @menu-dark-hover;
  .opacity(0);
  visibility: hidden;
  z-index: 4;
}

.menu-wrapper {
  > .menu {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background: @menu-dark;
    width: @menu-offset;
    overflow: hidden;
    .translate3d(@menu-offset);
    .transition(transform, @menu-animation-duration);
    .hideBackface();
    z-index: 3;
    display: none;
    .active {
      background-color: @menu-dark-active;
    }
    .menulevel0 {
      > li > ul > li {
        background-color: @menu-dark-soft;
      }
    }
    ul {
      .list();
      > .menuitem {
        position: relative;
        border-top: 1px solid @menu-dark-borderline;
        &:hover .expand {
          .translate3d(@menu-expand-offset);
          background: @menu-dark-hover;
        }
        &.active {
          .expand {
            display: none;
          }
        }
        &.plain {
          height: 70px;
        }
        &.menu-close {
          > a {
            .st();
            text-align: right;
            color: @menu-labels;
            .icon_close_md2();
            width: auto;
            height: auto;
            background-size: 32px;
            background-position: right;
            padding: 15px 32px 15px 0;
            &:hover {
              .icon_close_md2();
              width: auto;
              height: auto;
              background-size: 32px;
              background-position: right;
              padding-right: 32px;
              cursor: pointer;
            }
          }
        }
        > a {
          .mt();
          color: @white;
          padding: 19px 0 19px 21px;
          display: block;
          .transition(background, @hover-background-animation-duration);
          &:hover {
            text-decoration: none;
            background: @menu-dark-hover;
          }
          &:active {
            background: @menu-dark-active;
          }
          &.expand {
            position: absolute;
            border: 1px solid @menu-dark-borderline;
            border-right: none;
            width: 32px + @menu-expand-offset;
            top: 50%;
            margin-top: -21px;
            overflow: hidden;
            right: 0;
            padding: 5px;
            margin-right: @menu-expand-offset;
            .border-radius(0, 0, 5px, 5px);
            .translate3d(@menu-expand-offset * 2);
            .transition(transform, @menu-expand-animation-duration);
            .hideBackface();
            &:before {
              content: "";
              .icon_expand_inv();
              height: 30px;
            }
          }
        }
        > ul {
          display: none;
        }
      }
    }
    &.sub-menu {
      z-index: 3;
      background: @menu-bright;
      .translate3d(@menu-offset + @sub-menu-offset);
      width: @sub-menu-offset;
      @media screen and (max-width: @screen-sm) {
        .translate3d(@menu-offset + @sub-menu-offset);
      }
      .menulevel1 {
        li {
          border-top: 1px solid @menu-bright-borderline;
          a {
            .t6();
            display: block;
            color: @black;
            padding: 19px 21px 19px 21px;
            .transition(background, @hover-background-animation-duration);
            &:hover {
              background: @menu-bright-hover;
              text-decoration: none;
            }
          }
        }
      }
      .menulevel2 {
        display: block;
        li a {
          .st();
          color: @black;
          padding: 19px 21px 19px 21px;
          max-height: 59px;
          .transition(background, @hover-background-animation-duration);
          &:hover {
            background: @menu-bright-hover;
            text-decoration: none;
          }
        }
      }
    }
  }
  &.left {
    > .menu {
      left: 0;
      .translate3d(-@menu-offset);
      ul {
        > .menuitem {
          .expand {
            border: 1px solid @menu-dark-borderline;
            border-left: none;
            left: 0;
            padding: 5px;
            .border-radius(5px, 5px);
            .translate3d(-@menu-expand-offset);
            &:before {
              margin-left: @menu-expand-offset;
            }
          }
          &.menu-close {
            > a {
              text-align: left;
              padding: 15px 0 15px 32px;
              background-position: left;
            }
          }
          &:hover .expand {
            .translate3d();
            background: @menu-dark-hover;
          }
        }
      }
      &.sub-menu {
        left: 0;
        .translate3d(-(@menu-offset + @sub-menu-offset));
      }
    }
    &.sub-open {
      .sub-menu {
        .translate3d(-(@sub-menu-offset + @menu-offset));
        @media screen and (max-width: @screen-sm) {
          .translate3d(-@menu-offset);
        }
      }
    }
  }
  &.sub-open {
    .sub-menu {
      @media screen and (max-width: @screen-sm) {
        .translate3d(@menu-offset);
      }
    }
  }
}

.menu-open {
  #inner-wrap {
    .translate3d(-@menu-offset);
  }
  #offcanvas-wrapper {
    overflow: hidden;
  }
  #offcanvas-overlay {
    top: 0;
    visibility: visible;
    .opacity(.9);
  }
  &.submenu-open {
    #inner-wrap {
      .translate3d(-(@menu-offset + @sub-menu-offset));
      @media screen and (max-width: @screen-sm) {
        .translate3d(-@menu-offset);
      }
    }
    #offcanvas-overlay {
      .translate3d(@menu-offset);
      @media screen and (max-width: @screen-sm) {
        .translate3d(@menu-offset - @sub-menu-offset);
      }
    }
  }
  &.left {
    #inner-wrap {
      left: 0;
    }
    &.menu-open {
      #inner-wrap {
        .translate3d(@menu-offset);
      }
    }
    &.submenu-open {
      #inner-wrap {
        .translate3d(@menu-offset + @sub-menu-offset);
        @media screen and (max-width: @screen-sm) {
          .translate3d(@menu-offset);
        }
      }
      #offcanvas-overlay {
        .translate3d(-@menu-offset);
        @media screen and (max-width: @screen-sm) {
          .translate3d(-(@menu-offset - @sub-menu-offset));
        }
      }
    }
  }
}

.submenu-close {
  .st();
  padding: 15px 32px 15px 0;
  color: @menu-labels;
  text-align: right;
  display: block;
  .icon_close_soft();
  background-position: right;
  box-sizing: border-box;
  &:hover {
    text-decoration: none;
  }
}
