.extranet-teaser() {
  .teaser();
  background-color: @ai-blue-extra-2;

  &:before {
    background-color: @ai-blue-extra-2;
  }

  .list-item {
    color: @white;

    &[href] {
      &:hover,
      &:focus {
        background-color: @ai-blue-extra-1;
      }
    }
  }

  time {
    color: @ai-blue-extra-4;
  }

  .overline {
    color: @text-color;
  }
  .title {
    color: @white;
  }
}
