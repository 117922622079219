@font-face{
font-family:"Trade Gothic Next W01";
src:url("++theme++amnesty/fonts/8a6c3750-4927-4649-9dac-87049f5e9700.eot?#iefix");
src:url("++theme++amnesty/fonts/8a6c3750-4927-4649-9dac-87049f5e9700.eot?#iefix") format("eot"),url("++theme++amnesty/fonts/d4eef5b1-2f4a-42d6-ae2d-6220d71243be.woff2") format("woff2"),url("++theme++amnesty/fonts/57625f96-ca80-4602-9644-ec1803cb3ba3.woff") format("woff"),url("++theme++amnesty/fonts/6618d500-1727-4334-878e-1c4b95192f42.ttf") format("truetype"),url("++theme++amnesty/fonts/01ebe944-ca6c-41db-a3ba-b7e2a90536d9.svg#01ebe944-ca6c-41db-a3ba-b7e2a90536d9") format("svg");
}
@font-face{
font-family:"TradeGothicNextW01-Bold_693229";
src:url("++theme++amnesty/fonts/678017fc-bfca-4daa-a38a-b0f41bada5e7.eot?#iefix");
src:url("++theme++amnesty/fonts/678017fc-bfca-4daa-a38a-b0f41bada5e7.eot?#iefix") format("eot"),url("++theme++amnesty/fonts/b071c6d1-8b3a-4032-9c25-67daff28fae8.woff2") format("woff2"),url("++theme++amnesty/fonts/4d16ea6c-033a-41b1-8326-56a1d7392d5f.woff") format("woff"),url("++theme++amnesty/fonts/573b39c2-10f7-4b72-a656-89a329b76560.ttf") format("truetype"),url("++theme++amnesty/fonts/d079b7e1-7489-4f6d-b4c9-536742085cff.svg#d079b7e1-7489-4f6d-b4c9-536742085cff") format("svg");
}
@font-face{
font-family:"TradeGothicNextW01-Cond";
src:url("++theme++amnesty/fonts/5be4a815-5a22-4a8b-8a38-10467be39d2e.eot?#iefix");
src:url("++theme++amnesty/fonts/5be4a815-5a22-4a8b-8a38-10467be39d2e.eot?#iefix") format("eot"),url("++theme++amnesty/fonts/a8816340-0294-4243-8519-b2ccf08ca174.woff2") format("woff2"),url("++theme++amnesty/fonts/eb20bf3e-7a5c-4c98-8bc5-f38c1b3dd9f2.woff") format("woff"),url("++theme++amnesty/fonts/bbd9ed4b-21d6-4a35-a3eb-176c31258e03.ttf") format("truetype"),url("++theme++amnesty/fonts/2b33a704-0f65-4c4c-94d0-2bd00c0151af.svg#2b33a704-0f65-4c4c-94d0-2bd00c0151af") format("svg");
}
@font-face{
font-family:"TradeGothicNextW01-Bold_693232";
src:url("++theme++amnesty/fonts/62cdf702-7113-4955-9b29-d803174650c7.eot?#iefix");
src:url("++theme++amnesty/fonts/62cdf702-7113-4955-9b29-d803174650c7.eot?#iefix") format("eot"),url("++theme++amnesty/fonts/b559a389-e9a7-4140-80af-cc5f64350983.woff2") format("woff2"),url("++theme++amnesty/fonts/a43fe47f-004d-4b6d-9a72-23de9e08f66e.woff") format("woff"),url("++theme++amnesty/fonts/de22c287-e0dc-4287-8a0e-3f68a8788330.ttf") format("truetype"),url("++theme++amnesty/fonts/d7c320de-0c26-418a-b1d2-ef600169db34.svg#d7c320de-0c26-418a-b1d2-ef600169db34") format("svg");
}
@font-face{
font-family:"TradeGothicNextW01-HvCn_707049";
src:url("++theme++amnesty/fonts/02688edd-1e75-49c8-a9a9-0c718ac03687.eot?#iefix");
src:url("++theme++amnesty/fonts/02688edd-1e75-49c8-a9a9-0c718ac03687.eot?#iefix") format("eot"),url("++theme++amnesty/fonts/9ba6ab80-71bf-47b4-9628-cc6412b3045a.woff2") format("woff2"),url("++theme++amnesty/fonts/22f5a28a-c43f-43ad-bdf2-c1cb7abff9ec.woff") format("woff"),url("++theme++amnesty/fonts/f49159c0-b257-4efa-b603-ff98fe015336.ttf") format("truetype"),url("++theme++amnesty/fonts/b1cbaffb-5f24-4786-8c8d-6a1d0c3be8be.svg#b1cbaffb-5f24-4786-8c8d-6a1d0c3be8be") format("svg");
}
@font-face{
font-family:"TradeGothicW01-BoldCn20_675334";
src:url("++theme++amnesty/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.eot?#iefix");
src:url("++theme++amnesty/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.eot?#iefix") format("eot"),url("++theme++amnesty/fonts/1ba28851-f34b-4cb8-bf58-6a4b160ba249.woff2") format("woff2"),url("++theme++amnesty/fonts/5fdc935e-9e30-442a-bbe9-8d887b858471.woff") format("woff"),url("++theme++amnesty/fonts/616c4c87-a077-43f4-a9f4-f01267c13818.ttf") format("truetype"),url("++theme++amnesty/fonts/c901ad5f-a842-4549-a1f4-583a97f7e169.svg#c901ad5f-a842-4549-a1f4-583a97f7e169") format("svg");
}

/* latin */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("++theme++amnesty/fonts/WnznHAc5bAfYB2QRah7pcpNvOx-pjRV6eIWpYT5Kmgq3sw.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
