.light {
  #carousel-nav {
    background: none;

    ul li {
      padding: 10px;

      > a {
        border-left: 1px solid @ai-blue-extra-3;
        color: @ai-blue-extra-2;
        background: none;
        padding: 15px;

        &:hover {
          color: @white;
        }
      }
    }
  }

  .carousel-caption {
    background-color: @ai-blue-extra-dark;

    .carousel-teaser-outer-wrapper {
      .carousel-teaser-inner-wrapper {
        background: none;

        .carousel-teaser {
          background: none;

          h2 {
            .overline {
              color: @text-color;
            }

            .title {
              color: @white;
            }
          }

          .more-link {
            > span {
              background-color: transparent;
              color: @yellow;
            }
          }
        }
      }
    }
  }
}
