.discussion {
  .grid-center();
  .grid-border();

  margin-top: 90px;

  .comment {
    display: flex;
    flex: 0 1 auto;
    margin-bottom: 60px;
    background: @white;
    padding: 20px;
    box-shadow: #dddddd 0 0 30px 0;
  }

  .commentImage {
    max-width: 100px;
    flex-basis: 100px;
    flex: 0 0 auto;

    img {
      border-radius: 100%;
    }
  }

  .documentByLine {
    max-width: 150px;
    flex-basis: 150px;
    flex: 0 0 auto;
    box-sizing: border-box;
    padding: 0 20px;
  }

  input[type="submit"] {
    .t5();
    min-width: 300px;
    padding: 10px;
    background: @teal;
    color: #fff;
    border: none;
    border-radius: 3px;
  }
}

.reply-to-comment-button { display: none; }

#commenting {
  .grid-center();
  .grid-border();
}

.anonymous-discussion {
  .grid-center();
  .grid-border();

  > p { // Reactions
    .t6();
  }

  .anonymous-message-box {
    border: 1px solid @grey-5;
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    @media (min-width: @screen-md) {
      flex-direction: row;
      justify-content: space-between;
      padding: 0;

      .title {
        padding-left: 33px;
        width: 100% * (4 * @column-width + 3 * @gutter-width - @gutter-width) / @gridsystem-width;
      }

      .text {
        box-sizing: border-box;
        padding-top: 33px;
        padding-right: 33px;
        width: 100% * ((@column-width + @gutter-width) * 8 - @gutter-width) / @gridsystem-width;
      }
    }
  }
}
