.listingBar {
  border: 1px solid @grey-5;
  border-left: 0;
  .clearfix();
  > div {
    @media (min-width: @screen-md) {
      display: table;
    }
    &.pagecounter {
      display: none;
      @media (min-width: @screen-md) {
        display: block;
        float: left;
      }
    }
    &.navigation {
      width: 100%;
      position: relative;
      @media (min-width: @screen-md) {
        width: auto;
        float: right;
        position: static;
      }
    }
    span,
    a {
      .st();
      display: inline-block;
      color: @grey-4;
      padding: 20px 0;
      min-width: 40px;
      line-height: 0;
      text-align: center;
      border-left: 1px solid @grey-5;
      @media (min-width: @screen-md) {
        display: table-cell;
      }
    }
    > .pagenumbers {
      display: block;
      width: 100%;
      padding: 0 40px;
      box-sizing: border-box;
      white-space: nowrap;
      overflow-x: scroll;
      -webkit-transform: translateZ(0);
      @media (min-width: @screen-md) {
        display: table;
        padding: 0;
        width: auto;
      }
      &::-webkit-scrollbar {
          display: none;
      }
    }
    span {
      &.current-pagenumber {
        padding-left: 10px;
        text-align: left;
      }
      &.current-page {
        color: @black;
      }
    }
    a {
      &:hover,
      :focus,
      :active {
        background: @grey-5;
        color: @black;
        text-decoration: none;
      }
      &.next {
        padding: 20px;
        display: none;
        @media (min-width: @screen-md) {
          display: table-cell;
        }
        &:after {
          content: "›";
          padding-left: 3px;
        }
      }
      &.previous {
        padding: 20px;
        display: none;
        @media (min-width: @screen-md) {
          display: table-cell;
        }
        &:before {
          content: "‹";
          padding-right: 3px;
        }
      }
      &.next-mobile {
        font-size: 0;
        border-left: none;
        background: @body-bg;
        position: absolute;
        right: 0;
        top: 0;
        box-shadow: -5px 0px 10px -3px rgba(0, 0, 0, .4);
        padding: 0;
        height: 40px;
        -webkit-transform: translateZ(0);
        @media (min-width: @screen-md) {
          display: none;
        }
        &:before {
          content: "›";
          font-size: 25px;
          display: block;
          margin-top: 18px;
          color: @grey-3;
        }
      }
      &.previous-mobile {
        background: @body-bg;
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        box-shadow: 5px 0px 10px -3px rgba(0, 0, 0, .4);
        padding: 0;
        height: 40px;
        font-size: 0;
        -webkit-transform: translateZ(0);
        @media (min-width: @screen-md) {
          display: none;
        }
        &:before {
          content: "‹";
          font-size: 25px;
          display: block;
          margin-top: 18px;
          color: @grey-3;
        }
      }
    }
  }
}
