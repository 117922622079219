#navigation {
  .menuitem.action {
    .title {
      .st();
      display: inline-block;
      line-height: @menu-menuitem-height;
      color: @menu-labels;
      padding-left: 1em;
      padding-bottom: 0;
    }
    .language {
      .st();
      padding: .5em 1em;
      background: @menu-dark-hover;
      color: @white;
      margin-left: 1em;
      border-radius: 2px;
    }
    .sharing {
      .sharing(light);
      display: inline-block;
      vertical-align: middle;
      ul {
        width: auto;
        margin-left: 1em;
        li {
          float:left;
          width: auto;
          height: auto;
          display: inline-block;
          > a {
            display: block;
            padding: 0;
            height: 32px;
            width: 32px;
          }
        }
      }
    }
  }
}
