::-webkit-input-placeholder {
  color: @grey-3;
}

:-moz-placeholder {
  color: @grey-3;
}

::-moz-placeholder {
  color: @grey-3;
}

:-ms-input-placeholder {
  color: @grey-3;
}

body {
  counter-reset: fieldset;
}

select {
  width: 100%;

  &:not([multiple="multiple"]) {
    .icon_triangle;
    appearance: none;
    -webkit-appearance: none;
    -mox-appearance: none;
    border-radius: 0;
    background-position: right 20px center;
    background-size: 15px;
  }

  &[multiple="multiple"] {
    height: 100px;
  }
}

input[type="text"],
input[type="password"],
input[type="file"],
textarea,
select {
  width: 100%;
  .t5();
  padding: 0;
  padding: 10px 20px;
  background-color: #fff;
  border: 1px solid @grey-4;
  color: @grey-4;
  box-sizing: border-box;

  &:focus {
    border-color: @black;
    color: @black;
    outline: none;
  }
}

.field {
  margin-bottom: 30px;

  @media (min-width: @screen-xs) {
    padding-right: 40px;
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;

    + input + label, + label {
      display: inline-block;
      .noselect();
      .t5();
      padding: 0;
      padding-left: 40px;
      color: @grey-2;
      position: relative;

      &:before {
        position: absolute;
        top: 4px;
        left: 0;
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        background-image: none;
        background-position: -7px -7px;
        border: 1px solid @grey-2;
      }
    }

    &:checked {
      + input + label, + label {
        color: @black;

        &:before {
          .icon_ok(15px);
          background-position: center;
        }
      }
    }
  }

  input[type="radio"] {
    position: absolute;
    opacity: 0;

    + label {
      display: inline-block;
      .noselect();
      .t5();
      padding: 0;
      padding-left: 40px;
      color: @grey-3;
      position: relative;

      &:after {
        position: absolute;
        top: 10px;
        left: 6px;
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: transparent;
      }

      &:before {
        position: absolute;
        top: 4px;
        left: 0;
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid @grey-3;
      }
    }

    &:checked {
      + label {
        color: @black;

        &:after {
          background-color: @black;
        }
      }
    }
  }

  label, div.label {
    .t6();
    padding: 0;
    margin-bottom: 10px;
    display: block;
    color: @grey-2;
  }

  .label {
    .t3();
    padding: 0;
  }

  .fieldErrorBox {
    .st();
    padding: 0;
    color: @red;

    &:empty {
      display: none;
    }
  }

  &.error {
    input[type="text"],
    textarea {
      color: @red;
      border-color: @red;
      background-color: lighten(@red, 53);
    }
  }

  .formHelp {
    display: block;
    .st();
    padding: 0;
    padding-top: 10px;
    color: @grey-3;

    &:empty {
      display: none;
    }
  }

  span.required:not(.named-image-widget) {
    display: inline-block;
    min-width: 10px;
    &:before {
      content: '*';
    }
  }
}

.formControls, .plone-modal {
  margin: 40px 0 40px 0;

  @media (min-width: @screen-md) {
    margin: 40px 0 40px 80px;
  }

  input[type="submit"],
  input[type="reset"],
  input[type="button"] {
    .t5();
    min-width: 300px;
    padding: 10px;
    background: @teal;
    color: #fff;
    border: none;
    border-radius: 3px;
  }

  input[type="reset"] {
    background: @red;
  }

  input[type="submit"][name*="form.buttons.cancel"], input[type="submit"][name*="form.button.cancel"], input.destructive {
    background: @red;
  }
}

.formfolder {
  background-color: @body-bg;

  form {
    margin: 0;
  }

  .formControls {
    margin-bottom: 0;
  }
}

.plone-modal .formfolder {
  background: @white;
}

#archetypes-fieldname-office-phone {
  display: none;
}

.field.ArchetypesCalendarWidget {
  .clearfix();

  .plone_jscalendar {
    > span {
      float: left;
    }

    > span:nth-child(odd) {
      display: block;
      padding: 15px;
    }
  }

  .select-target.select-theme-default {
    min-width: 150px;
    width: auto;
  }
}

.plone_jscalendar {
  select {
    @media (min-width: @screen-lg) {
      min-width: 150px;
      width: auto;
    }
  }
}

fieldset {
  border: 0;
  border-top: 1px solid @grey-4;
  padding: 0;
  padding-top: 90px;
  position: relative;

  > legend {
    .t3();
    margin: 0;
    padding: 0;
    position: absolute;
    top: 20px;
  }
}

.PFGFieldsetWidget {
  border: 0;
  border-top: 1px solid @grey-4;
  padding: 0;
  padding-top: 90px;
  position: relative;

  > legend {
    .t3();
    margin: 0;
    padding: 0;
    padding-left: 50px;
    position: absolute;
    top: 20px;

    &::before {
      counter-increment: fieldset;
      content: counter(fieldset);
      width: 30px;
      height: 30px;
      display: block;
      text-align: center;
      border: 2px solid #362c2c;
      border-radius: 50%;
      position: absolute;
      left: 0px;
      top: -4px;
      line-height: 30px;
      font-size: 20px;
    }
  }
}

.template-login_form,
.template-mail_password_form,
.template-logged_out,
.template-edit,
.template-folder_contents,
.template-folder,
.template-image,
.template-file,
.template-amnesty-web-event,
.template-amnesty-web-article,
.template-amnesty-web-portalpage,
.template-amnesty-web-magazine,
.template-amnesty-web-carouselteaser,
.template-amnesty-web-dropdownmenuitem {
  #content {
    .grid-center;
  }
}

#formfield-form-widgets-IDublinCore-subjects {
  display: none;
}

.template-login_form,
.template-logged_out,
.template-mail_password_form {
  .formControls {
    margin-left: 0;
  }
}

.template-mail_password_form {
  fieldset {
    margin: 0;
    padding: 0;
    border: none;

    legend {
      display: none;
    }
  }
}


table.datagridwidget-table-view {
    width: 100%;
}

input.datagrid-radio-button {
    /* Radio buttons try to use Plone's default text input 
       field CSS properties, like borders and background shade image */
    border: 0;
    background: transparent;
}

td.datagridwidget-radio {
    /* IE adds quite heavy padding - force more sensible values */
    padding: 0;
    border: 0;
    margin: 0;
}

td.datagridwidget-select {
    /* Looks like browser want to shrink drop down menu boxes 
       to zero width if there is any fight from the space.
       
       Workaround is to force select width. Override this value
       according to your needs */
    width: 5em;
}

tr.datagridwidget-row {
    vertical-align: top;
}

/*
    Workaround for IE which cannot propeply handle
    background images in cloned TR input fields.
    Plone is shipped with default input background gradient image
 */
.datagridwidget-cell input {
    background: white;
    background-image: none;
}

tr.datagridwidget-empty-row {
    /* Hidden row with default selection widget values */
    display: none;
}

td.datagridwidget-cell {
    vertical-align: top;
    padding: 2px;
}

td.datagridwidget-manipulator {
    width: 16px;
    text-align: center;
    padding: 0;
    margin: 0;  
}

td.datagridwidget-manipulator img {
    cursor: pointer;    
}

td.datagridwidget-hidden-data {
    display: none;  
}


a.datagridwidget-add-button, a.datagridwidget-add-button:hover {
    text-decoration: none;
    cursor: pointer;
    padding: 1px 1px 1px 15px;
    display: block;
    text-align: right;
}

.dgw-hidden-column {
    display: none;
}

/* 
    Merged from historical datagridwidget.css file 
    as fix for: http://plone.org/products/datagridfield/issues/30
*/

/*  styling for the datagridfield */

div.ArchetypesDataGridWidget table td img {
    display: block;
    cursor: pointer;
}

div.ArchetypesDataGridWidget table td input, 
div.ArchetypesDataGridWidget table td select {
    width:100%;
}
