#content-core .issuuembed {
  @media (min-width: @screen-md) {
    height: 850px !important;
  }
  height: 450px !important;
  background-color: #282828;
  width: 100% !important;
}


#content-core .issuuembed_rappact {
  @media (min-width: @screen-md) {
    height: 500px !important;
  }
  height: 280px !important;
  background-color: #282828;
  width: 100% !important;
}
