footer {
  margin-top: 2em;
  .st();
  form {
    margin: 0;
  }
 
  #cookie-settings-link a {
    &:before {
      content: "";
      float: left;
      content: "";
      display: block;
      width: 32px;
      height: 25px;
      .icon_cookie_settings_soft(@dims: 25px);
    }
  }

  section {
    padding: 1em 100% * @margin-width / @gridsystem-width;
    border-top: 1px solid @grey-5;
    a {
      color: @text-color-soft;
      &:hover, &:focus {
        text-decoration: none;
        color: @text-color;
      }
    }
    p,
    span {
      color: @text-color-soft;
    }
    .row {
      @media(min-width: @screen-md) {
        max-width: @gridsystem-width;
        margin: 0 auto;
      }
      > ul > li {
        float: left;
        width: 100%;
        @media (min-width: @screen-sm) {
          .columns(4);
          margin-left: 100% * @gutter-width / @gridsystem-width;
        }
        &:first-child {
          margin-left: 0;
        }
      }
      &.bottom {
        .clearfix();
        position: relative;
        > div {
          float: left;
          display: table-cell;
        }
        @width-left-small: 100% * (6 * @column-width + (6 - 1) * @gutter-width) / @gridsystem-width;
        @width-left-large: 100% * (8 * @column-width + (8 - 1) * @gutter-width) / @gridsystem-width;
        > .left {
          .columns(6);
          @media (min-width: @screen-sm) {
            .columns(8);
          }
          > div {
            float: left;
          }
          > .address {
            @media (min-width: @screen-sm) {
              .columns(6);
            }
          }
          > .location {
            @media (min-width: @screen-sm) {
              .columns(6);
              margin-left: 100% * @gutter-width / @gridsystem-width;
            }
          }
        }
        > .right {
          background-image: url("++theme++amnesty/images/logo_footer_mobile.svg");
          .columns(6);
          @margin: 100% * @gutter-width / @gridsystem-width;
          margin-left: @margin;
          position: absolute;
          top: 0;
          bottom: 0;
          left: @margin + @width-left-small - (100% * @margin-width / @gridsystem-width);
          background-repeat: no-repeat;
          background-position: right;
          @media (min-width: @screen-sm) {
            background-image: url("++theme++amnesty/images/logo_footer.svg");
            .columns(4);
            margin-left: 100% * @gutter-width / @gridsystem-width;
            left: @margin + @width-left-large - (100% * @margin-width / @gridsystem-width);
          }
        }
      }
    }
    .title {
      .t6();
      margin-top: 0;
      padding-top: 0;
      color: @grey-2;
    }

    .social-icons {
      display: flex;
      flex-wrap: wrap;
      margin: 20px 0;
      @media(min-width: @screen-sm) {
        margin: 0;
        margin-top: 40px;
      }
      > a {
        flex-basis: 100%;
        @media(min-width: @screen-sm) {
          flex-basis: 50%;
        }
        &:before {
          float: left;
          margin-top: -4px;
          content: "";
          display: block;
          width: 32px;
          height: 32px;
        }
      }
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin-top: 0;
      &.link-list {
        >li {
          &:after {
            content: ".";
            color: @grey-3;
          }
          > a {
            padding: 0;
          }
          &:first-child > a {
            padding-left: 0;
          }
          &:last-child:after {
            content: none;
          }
        }
      }
      > li {
        display: inline-block;
      }
    }
    input {
      .st();
      display: block;
      float: left;
      box-sizing: border-box;
      border: 1px solid @grey-4;
      padding: 2px 10px 3px 10px;
      width: 70%;
      line-height: 26px;
      height: 33px;
      &:focus {
        border-color: @black;
        color: @black;
        outline: none;
      }
      &[type="submit"] {
        background: @grey-1;
        border: 1px solid @grey-1;
        color: @white;
        margin-left: -1px;
        width: 30%;
      }
    }
    ul,
    span {
      max-width: @gridsystem-width;
      display: block;
    }
    #footer-location {
      display: block;
      margin: 20px 0;
      &:before {
        content: "";
        float: left;
        margin-top: -4px;
        .icon_maps_soft();
        display: block;
        width: 32px;
        height: 32px;
      }
      &:hover {
        &:before {
          .icon_maps_dark();
        }
      }
    }
    #footer-facebook {
      &:before {
        .icon_facebook_soft();
      }
      &:hover {
        &:before {
          .icon_facebook_dark();
        }
      }
    }
    #footer-twitter {
      &:before {
        .icon_twitter_soft();
      }
      &:hover {
        &:before {
          .icon_twitter_dark();
        }
      }
    }
    #footer-bluesky {
      &:before {
        .icon_bluesky_soft();
      }
      &:hover {
        &:before {
          .icon_bluesky_dark();
        }
      }
    }
    #footer-youtube {
      &:before {
        .icon_youtube_soft();
      }
      &:hover {
        &:before {
          .icon_youtube_dark();
        }
      }
    }
    #footer-instagram {
      &:before {
        .icon_instagram_soft();
      }
      &:hover {
        &:before {
          .icon_instagram_dark();
        }
      }
    }
    .clearfix();
  }
}
